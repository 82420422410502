import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { currency } from '../Utils';
import { config } from '../Configurator'
import Documents from './DocumentsCause'

class DelegateView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entry: {},
            customer_reference: null,
            applicant_id: this.props.id,
            name: null,
            forms: config().forms
        }
    }

    componentDidMount() {
        
        // get delegate details
        axios.post(config().base_url + 'SearchLearnerDelegatesJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "pRequest": {
                "SearchType": null,
                "SearchText": null,
                "DelegateCode": this.props.id,
                "DelegateStatusCode": null,
                "DelegateTypeCode": null,
                "startDate": null,
                "endDate": null,
                "recordStartNo": 1,
                "recordEndNo": 10,
                "DelegateCustomFieldNumbers": null,
                "DelegateCustomFieldData": null 
              }
               
          }
            )
        .then(response => {
            
            // console.log(response);
            
            // console.log('applicant_id = ' + response.data.d.CaseApplicant.ApplicantID);
            // console.log('customer_ref = ' + response.data.d.CaseApplicant.CustomerReference);

            this.setState({
                entry: response.data.d.CaseApplicant, 
                applicant_id: response.data.d.CaseApplicant.ApplicantID,
                customer_reference: response.data.d.CaseApplicant.CustomerReference,
                name: (response.data.d.CaseApplicant.MainApplicant.Firstname ?? "") + ' ' + (response.data.d.CaseApplicant.MainApplicant.Surname ?? "")});
        })
        .catch(error => {
            console.log(error);
        });


    }
    render() {
        const { entry, customer_reference, name, applicant_id } = this.state;
        return (
            <div>
                <div className="row mt-3">
                    <div className="col-md-8">
                        <div className="row mb-2">
                            <div className="col-9">
                                <h2>Summary</h2>
                            </div>
                            {/* <div className="col-3 text-right">
                                <Link className="float-right btn btn-light btn-sm" to='/cases'>&larr; Back</Link>
                            </div> */}
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">Ref:<br /><strong>{ entry.CustomerReference }</strong></div>
                                        <div className="mb-3">Name:<br /><strong>{ entry.Firstname }</strong></div>
                                        <div className="mb-3">Customer Name:<br /><strong>{ entry.CustomerCode1 }</strong></div>
                                        <div className="mb-3">Telephone:<br /><strong>{ entry.Telephone}</strong></div>
                                        <div className="mb-3">Mobile:<br /><strong>{ entry.Mobile }</strong></div>
                                        <div className="mb-3">Email:<br /><strong>{ entry.Email }</strong></div>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div className="mb-3">Date Of Birth:<br /><strong>{ entry.DateOfBirth }</strong></div>
                                        <div className="mb-3">National Insurance Number:<br /><strong>{ entry.NationalInsuranceNumber }</strong></div>
                                        <div className="mb-3">Delegate Points Available:<br /><strong>{ entry.DelegatePointsAvailable }</strong></div>
                                    </div>
                                    <div className="col-md-1"> </div>
                                    <div className="col-md-3">
                                    <div className="mb-3">Status:<br /><strong>{ entry.DelegateStatus }</strong></div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-footer">
                            <br />
                                <div className="row">
                                    <div className="col-md-4">Total Loan Amount<br /><h2>{ currency(entry.NetClaim) }</h2></div>
                                    <div className="col-md-4">Current Balance<br /><h2>{ currency(entry.TotalBalance) }</h2></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h2 className="mb-3">Documents</h2>
                        <Documents customer_reference={this.state.customer_reference} applicant_id={this.state.applicant_id}></Documents>
                    </div>
                </div>
                </div>
        )
    }

}

export default DelegateView;