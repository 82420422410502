import React from 'react';
import Navbar from '../components/Navbar';
import KPI2 from '../components/KPI2';
import { config } from '../Configurator'

export default function Dashboard() {
    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">


                <div className='row'>
                    <div className='col-md-8'>
                        <p>DASHBOARD</p>
                        <h1>Welcome back, { config().personName.split(" ")[0] }</h1>
                    </div>
                </div>

                <br />

                <div className='row'>
                    <div className='col-md-12'>
                        <KPI2></KPI2>                        
                    </div>
                </div>

            
            </div>

        </div>
    );
}