import React from 'react';
import { Link} from "react-router-dom";
import { config } from '../Configurator';
import './../components/CssCustomizer';
import CssCustomizer from './../components/CssCustomizer';
import LetteredAvatar from 'react-lettered-avatar';

const Navbar = () => {

    return(
        <nav className={ 'navbar navbar-expand-lg ' + (config().navbarClass==='white' ? 'navbar-light bg-white' : 'navbar-dark bg-dark') }>
                    <CssCustomizer></CssCustomizer>

        <div className="container">
            <Link to="/dash" className="navbar-brand">
                <img src={ config().logo } height="30" alt="Logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
        
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">

                {/* Admin BrokerLender Master Nav */}
                <li className="nav-item mx-1"><Link className="nav-link" to="/dash"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" />  Home</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/prospects"><img src={'/images/' + config().navIconClass + '/lead.svg'} height="26" alt="Prospects" />  Prospects</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/customers"><img src={'/images/' + config().navIconClass + '/person-24px.svg'} height="26" alt="Customers" />  Customers</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/cases"><img src={'/images/' + config().navIconClass + '/work-24px.svg'} height="26" alt="Cases" />  Cases</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/sources"><img src={'/images/' + config().navIconClass + '/source.svg'} height="26" alt="Sources" />  Sources</Link></li>

                {/* Admin Dashboard Only Nav */}
                {/* <li className="nav-item mx-1"><Link className="nav-link" to="/dashboard"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" />  Dashboard</Link></li> */}

                {/* Admin Training Master Nav */}
                {/* <li className="nav-item mx-1"><Link className="nav-link" to="/dash"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" />  Home</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/prospects"><img src={'/images/' + config().navIconClass + '/lead.svg'} height="26" alt="Prospects" />  Prospects</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/customers"><img src={'/images/' + config().navIconClass + '/person-24px.svg'} height="26" alt="Customers" />  Customers</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/courses"><img src={'/images/' + config().navIconClass + '/work-24px.svg'} height="26" alt="Courses" />  Courses</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/delegates"><img src={'/images/' + config().navIconClass + '/source.svg'} height="26" alt="Delegates" />  Learners</Link></li> */}

                {/* Managers Training Master Nav */}
                {/* <li className="nav-item mx-1"><Link className="nav-link" to="/dash"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" />  Home</Link></li>
                <li class="nav-item mx-1 dropdown">
                    
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={'/images/' + config().navIconClass + '/work-24px.svg'} height="26" alt="Courses" /> <span style={{ opacity: '0.5' }}>Courses</span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to="/courses">Booked Courses</Link>
                        <Link className="dropdown-item" to="/courses">Open Courses</Link>
                    </div>
                </li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/delegates"><img src={'/images/' + config().navIconClass + '/source.svg'} height="26" alt="Delegates" />  Learners</Link></li> */}
                {/* <li className="nav-item mx-1"><Link className="nav-link" to="/delegates"><img src={'/images/' + config().navIconClass + '/source.svg'} height="26" alt="Delegates" />  Calendar</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/delegates"><img src={'/images/' + config().navIconClass + '/source.svg'} height="26" alt="Delegates" />  Community</Link></li> */}


                {/* Learner Training Master Nav */}
                {/* <li className="nav-item mx-1"><Link className="nav-link" to="/dash"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" />  Home</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/courses"><img src={'/images/' + config().navIconClass + '/work-24px.svg'} height="26" alt="Courses" />  Courses</Link></li> */}

                {/* Admin Agency Master Nav */}
                {/* <li className="nav-item mx-1"><Link className="nav-link" to="/dash"><img src={'/images/' + config().navIconClass + '/home-black-18dp.svg'} height="26" alt="Home" />  Home</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/customers"><img src={'/images/' + config().navIconClass + '/person-24px.svg'} height="26" alt="Customers" />  Clients</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/cases"><img src={'/images/' + config().navIconClass + '/work-24px.svg'} height="26" alt="Cases" />  Projects</Link></li>
                <li className="nav-item mx-1"><Link className="nav-link" to="/sources"><img src={'/images/' + config().navIconClass + '/source.svg'} height="26" alt="Sources" />  Tasks</Link></li> */}

            </ul>
            <ul className="navbar-nav float-right">
                <li className="nav-item dropdown float-right">

                    <span className='float-left mr-3'>
                        <LetteredAvatar name={ config().personName } size={42} />
                    </span>

                    <a className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {/* <img src={ config().personProfileImage } className="float-left img-round mr-2" height="25" /> */}
                        { config().personName }<br />
                        <small>{ config().personFunction }</small>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                        {config().nav_links.map((value, index) => {
                            return <Link key={index} className="dropdown-item" to={value[0]}>{value[1]}</Link>
                        })}

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/logout">Logout</Link>
                    </div> 
                </li>
            </ul>
            </div>
        </div>
      </nav>
        );

}

export default Navbar;