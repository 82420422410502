import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { config } from '../Configurator';
import { currency, getAccountsAnalytics, getFinancialYearSummary, getDataAnalyticsByDateRange } from '../Utils';

class KPI extends Component {

    constructor(props) {
        super(props)
        this.state = {
            kpi: [
            //    {'title': '#', 'value': '#'},
            //    {'title': '#', 'value': '#'},
            //    {'title': '#', 'value': '#'},
            //    {'title': '#', 'value': '#'},

            //    {'title': '#', 'value': '#'},
            //    {'title': '#', 'value': '#'},
            //    {'title': '#', 'value': '#'},
            //    {'title': '#', 'value': '#'},

            ]
        }
    }

    componentDidMount() {
 
        // #1st call
       getAccountsAnalytics().then(response => {

            let kpi = this.state.kpi;

            // if(response) {
            //     kpi.push({'title': 'Sales Invoices Today', 'value': '£' + currency(response[1].Value)});
            //     kpi.push({'title': 'Sales Invoices Current Week', 'value': '£' + currency(response[2].Value)});
            //     kpi.push({'title': 'Sales Invoices Previous Week', 'value': '£' + currency(response[0].Value)});
            //     kpi.push({'title': 'Sales Invoices Current Year', 'value': '£' + currency(response[3].Value)});
            //     kpi.push({'title': 'Sales Invoices Next Year', 'value': '£' + currency(response[4].Value)});
            // }

            // Training FAKE example
            if(response) {
                kpi.push({'title': 'Total Bookings', 'value': (response[1].Value)});
                kpi.push({'title': 'Number of Learners', 'value': (response[2].Value)});
                kpi.push({'title': 'Upcoming Courses', 'value': (response[0].Value)});
                kpi.push({'title': 'Total Invoiced', 'value': '£' + currency(response[3].Value)});
                // kpi.push({'title': 'Sales Invoices Next Year', 'value': '£' + currency(response[4].Value)});
            }
            

            this.setState({kpi: kpi }, () => {
                // #2nd call
                getFinancialYearSummary().then(response => {
                    // todo: do something with response
                    let kpi = this.state.kpi;
                    this.setState({kpi: kpi}, () => {
                        // 3rd call
                        getDataAnalyticsByDateRange().then(response => {
                            // todo: do something with response
                            let kpi = this.state.kpi;
                            this.setState({kpi: kpi});
                        })

                    })
                }) 

            });

        });
        
    }

    render() {
        const { kpi } = this.state;
        return (
            <div>
                <div className="row mt-2 mb-2">
                    {kpi.map((entity, index) => (  
                        <div className="col-3 mt-3 mb-3">
                            <div key={index} className="card bg-primary text-white">
                                <div className='card-body'>
                                    <p className="card-title">{ entity.title }</p>
                                    <h5 className="card-text text-right">
                                        <hr />
                                        { entity.value }</h5>
                                </div>
                            </div>
                        </div>
                        ))
                        }                    
                </div>
            </div>
        )
    }

}

export default KPI;