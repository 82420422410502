import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { currency, orderResult } from '../Utils';
import Loading from './Loading';
import { config } from '../Configurator'

class DelegatesRecent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cases: [],
            loading: true
        }
    }

// Results filtered by Live in JobStatus

    componentDidMount() {

        let status = this.props.status ?? "Active";

        axios.post(config().base_url +  'SearchLearnerDelegatesJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "pRequest": {
                "SearchType": "",
                "SearchText": "",
                "DelegateCode": "",
                "DelegateStatusCode": "",
                "DelegateTypeCode": "",
                "startDate": "",
                "endDate": "",
                "recordStartNo": "0",
                "recordEndNo": "100"
                }
    })
        .then(response => {

            let filteredArray = response.data.d.Records.filter(function(itm){
                return (itm.JobStatus == status);
            });
            let r = orderResult(filteredArray, 'Code', 'desc');
            this.setState({loading: false, cases: r.slice(0,3)});
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    render() {
        const { cases, loading } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-12 mt-3">
                        
                        {
                            loading ? <Loading></Loading> : null
                        }
                        {
                            cases.length ?
                            cases.map(entry => {
                                return <div key={ entry.Code }>
                                <div className="card mb-3">
                                <div className="card-body row">
                                    <div className="col-3">Code:<br /><strong>{entry.Code}</strong></div>
                                    <div className="col-2">Firstname:<br /><strong>{entry.Firstname}</strong></div>
                                    <div className="col-2">Surname:<br /><strong>{entry.Surname}</strong></div>
                                    <div className="col-2">Delegate Status:<br /><strong>{entry.DelegateStatus}</strong></div>
                                    <div className="col-2">Telephone:<br /><strong>{entry.Telephone}</strong></div>
                                    <div className="col-1">
                                        <Link to={ '/delegate/' + entry.Code } className="btn-more"></Link>
                                    </div>
                                </div>
                            </div>   
                            </div> 
                            }) : 
                            loading ? null : 
                            <div className='card mb-3'>
                                <div className='card-body row'>
                                    <div className='col-12'>
                                        No current records
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                {loading ? null : 
                <div className="row">
                    <div className="digitus-view-all">
                        <Link to='/delegates'>View All</Link>
                    </div>
                </div>
                }
            </div>
        )
    }

}

export default DelegatesRecent;