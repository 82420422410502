import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { config } from '../Configurator'
import $ from 'jquery';

class Documents extends Component {

    constructor(props) {
        super(props)
        this.state = {
            initialized: false,
            context: 'cases',
            applicant_id: null,
            customer_reference: null,
            case_status: null,
            b2b: null,
            forms: null
        }
    }

    componentDidMount() {
        let context = this.props.context ?? 'cases';

        
        // get case details
        axios.post(config().base_url + 'GetCase', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "CaseReference": this.props.applicant_id
        })
        .then(response => {

            // console.log(response.data.d.CaseApplicant);
            // console.log('applicant_id = ' + response.data.d.CaseApplicant.ApplicantID);
            // console.log('customer_ref = ' + response.data.d.CaseApplicant.CustomerReference);
            let forms = this.groupForms()
            

            this.setState({
                forms: forms,
                context: context, 
                initialized: true,
                applicant_id: response.data.d.CaseApplicant.ApplicantID,
                // b2b: (response.data.d.CaseApplicant.MainApplicant.WorkTelephone==null) ? false : true , 
                b2b: (response.data.d.CaseApplicant.B2B==true) ? true : false ,
                case_status: response.data.d.CaseApplicant.CaseStatus ,
                customer_reference: response.data.d.CaseApplicant.CustomerReference}, 
                function(){
                    $('.card-body-documents h3').each(function(){
                        let category = $(this).data('id')
                        let totals = $(".btn[data-id='" + category + "']").length
                        if(totals===0) {
                            $(this).hide()
                        } else {
                            $(this).show()
                        }
                    })
        
                })
        })
        .catch(error => {
            console.log(error);
        });

    }

    groupForms() 
    {
        let forms = config().forms
        let ret = {};
        for(let i=0; i<forms.length; i++) {
            let key = forms[i].group ?? "General"
            if(typeof ret[key] == 'undefined') {
                ret[key] = [];
            }
            ret[key].push(forms[i])
        }
        return ret
    }

    handleUpload()
    {   
    }

    getPdf(entry)
    {
        // entry == { "name": "DOCX COMMON WITH LETTERGROUP",  "LetterGroupCode": "A", "type": "docx", "resource": "test" }
        
        // get documents
        axios.post(config().base_url + 'CreateDocumentToPdf', {
            "pRequest": {
                "Mode":"Case" ,
                "Reference": this.state.applicant_id,
                "LetterCode": entry.resource ?? "",
                "LetterGroupCode": entry.LetterGroupCode ?? "",
                "ReturnDocType": entry.ReturnDocType ?? ""
            },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            let url = (response.data.d.ReturnUrl);
            var link = document.createElement('a');
            link.href = url;
            link.target = "_blank"
            link.download = 'download_' + entry.resource + '.pdf';
            link.dispatchEvent(new MouseEvent('click'));
            // this.setState({entry: response.data.d.CaseApplicant});
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        const { context, forms, applicant_id, customer_reference, initialized, b2b, case_status } = this.state;
        const that = this
        return (initialized===true) ? (
            <div>

                <div className="card">
                    <div className="card-body card-body-documents accordion-closed">

                        {
                                Object.keys(forms).map(function (key) {
                                return forms[key].length > 0 ? [<div><h3 data-id={key} className='mt-2 open-accordion'>{key}</h3></div>, forms[key].map(entry => {

                                    let get_query = '?';
                                    let state_b2b = b2b;
                                    let state_case_status = case_status; 
                                    let entry_b2b = entry.b2b ?? null;
                                    let entry_case_status = entry.case_status ?? null;


                                    // console.log(state_case_status + ", context=" + context);

                                    // show entry if b2b field is not set (default) 
                                    // let logic_b2b = ((entry_b2b===false && state_b2b===false) || (entry_b2b===true && state_b2b===true)); 
                                    let logic_b2b = (entry_b2b===true && state_b2b===true || entry_b2b===false && state_b2b===false); 

                                    let logic_case_status_prospect = (state_case_status==='Prospect' && entry_case_status==='prospect' && ((entry_b2b===null || (entry_b2b===true && state_b2b===true) || (entry_b2b===false && state_b2b===false))) ); 
                                    let logic_case_status_live = (state_case_status==='Live' && entry_case_status==='live' && ((entry_b2b===null || (entry_b2b===true && state_b2b===true) || (entry_b2b===false && state_b2b===false))) ); 
                                    //OR Additions
                                    let logic_case_status_dead = (state_case_status==='Dead' && entry_case_status==='dead' && ((entry_b2b===null || (entry_b2b===true && state_b2b===true) || (entry_b2b===false && state_b2b===false))) ); 
                                    let logic_case_status_recovery = (state_case_status==='Recovery' && entry_case_status==='recovery' && ((entry_b2b===null || (entry_b2b===true && state_b2b===true) || (entry_b2b===false && state_b2b===false))) ); 
                                    let logic_case_status_workout = (state_case_status==='Workout' && entry_case_status==='workout' && ((entry_b2b===null || (entry_b2b===true && state_b2b===true) || (entry_b2b===false && state_b2b===false))) ); 

                                    // console.log('logic conditions to be in the list = ' + (!entry_b2b && !entry_case_status) + ', ' + logic_case_status + ', ' + logic_b2b );

                                    if((entry_b2b===null && entry_case_status===null) || logic_case_status_live || logic_case_status_dead || logic_case_status_recovery || logic_case_status_workout || logic_case_status_prospect) {

                                        // console.log('logic passes ____ entry type=' + entry.type + ' context=' + context);
                                        //console.log(entry.name + " OK (" + logic_case_status_live + ',' + logic_case_status_prospect + ')');

                                        if(applicant_id) {
                                            get_query += "applicant_id=" + applicant_id + "&";
                                        }
                                        if(customer_reference) {
                                            get_query += "customer_reference=" + customer_reference + "&";
                                        }

                                        // LINKS
                                        if (entry.type === 'link' && applicant_id && !customer_reference) {
                                            return <Link data-id={key} className="btn btn-outline-dark btn-block btn-sm" to={entry.resource + '?applicant_id=' + applicant_id}>{entry.name}</Link>
                                        } 
                                        if (entry.type === 'link' && applicant_id && customer_reference) {
                                            return <Link data-id={key} className="btn btn-outline-dark btn-block btn-sm" to={entry.resource + '?customer_reference=' + customer_reference + '&applicant_id=' + applicant_id}>{entry.name}</Link>
                                        } 
                                        if (entry.type === 'link_prospect' && context=='prospects') {
                                            return <Link data-id={key} className="btn btn-outline-dark btn-block btn-sm" to={entry.resource + get_query}>{entry.name}</Link>
                                        } 
                                        if (entry.type==='link_case' && (context==='cases' || context==='live')) {
                                            return <Link data-id={key} className="btn btn-outline-dark btn-block btn-sm" to={entry.resource  + get_query}>{entry.name}</Link>
                                        } 
                                        if (entry.type==='link_recovery' && (context==='cases' || context==='recovery')) {
                                            return <Link data-id={key} className="btn btn-outline-dark btn-block btn-sm" to={entry.resource  + get_query}>{entry.name}</Link>
                                        } 
                                        if (entry.type==='link_workout' && (context==='cases' || context==='workout')) {
                                            return <Link data-id={key} className="btn btn-outline-dark btn-block btn-sm" to={entry.resource  + get_query}>{entry.name}</Link>
                                        } 
                                        if (entry.type==='link_dead' && (context==='cases' || context==='dead')) {
                                            return <Link data-id={key} className="btn btn-outline-dark btn-block btn-sm" to={entry.resource  + get_query}>{entry.name}</Link>
                                        }
                                        


                                        // DOCX
                                        if (entry.type === 'docx') {
                                            return <Link data-id={key} target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                        } 
                                        if (entry.type === 'docx_prospect' && context=='prospects') {
                                            return <Link data-id={key} target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                        } 
                                        if (entry.type==='docx_case' && (context==='cases' || context==='live')) {
                                            return <Link data-id={key} target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                        } 
                                        //OR Addition
                                        if (entry.type==='docx_recovery' && (context==='cases' || context==='recovery')) {
                                            return <Link data-id={key} target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                        } 
                                        if (entry.type==='docx_workout' && (context==='cases' || context==='workout')) {
                                            return <Link data-id={key} target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                        } 
                                        if (entry.type==='docx_dead' && (context==='cases' || context==='dead')) {
                                            return <Link data-id={key} target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                        } 
                                        

                                        // PDF
                                        if (entry.type === 'pdf') {
                                            return <div data-id={key} className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => that.getPdf(entry)}>{entry.name}</div>
                                        }
                                        if (entry.type === 'pdf_prospect' && context=='prospects') {
                                            return <div data-id={key} className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => that.getPdf(entry)}>{entry.name}</div>
                                        } 
                                        if (entry.type==='pdf_case' && (context==='cases' || context==='live')) {
                                            return <div data-id={key} className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => that.getPdf(entry)}>{entry.name}</div>
                                        } 
                                        if (entry.type==='pdf_recovery' && (context==='cases' || context==='recovery')) {
                                            return <div data-id={key} className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => that.getPdf(entry)}>{entry.name}</div>
                                        } 
                                        if (entry.type==='pdf_workout' && (context==='cases' || context==='workout')) {
                                            return <div data-id={key} className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => that.getPdf(entry)}>{entry.name}</div>
                                        } 
                                        if (entry.type==='pdf_dead' && (context==='cases' || context==='dead')) {
                                            return <div data-id={key} className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => that.getPdf(entry)}>{entry.name}</div>
                                        } 
                                    } else {
                                        // console.log(entry.name + " FAILS");
                                    }


                                })] : null
                            })
                        }

                        {/* <br /><br /> */}

                        {/* <div className="btn btn-outline-dark btn-block btn-sm">Make Payment</div> */}
                        {/* <div className="btn btn-outline-dark btn-block btn-sm">Upload Docs</div>

                        <div className="upload-box">
                            <div className="input-group mt-3">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="myInput1" aria-describedby="myInput1" />
                                    <label className="custom-file-label" htmlFor="myInput1">Choose file</label>
                                </div>
                            </div>
                            <div className="meter">
                                <span><span className="meter-progress"></span></span>
                            </div>
                        </div>


                        <div className="upload-box">
                            <div className="input-group mt-3">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="myInput2" aria-describedby="myInput2" />
                                    <label className="custom-file-label" htmlFor="myInput2">Choose file</label>
                                </div>
                            </div>
                            <div className="meter">
                                <span><span className="meter-progress"></span></span>
                            </div>
                        </div>

                        <div className="upload-box">
                            <div className="input-group mt-3">
                                <div className="custom-file">
                                    <input type="file" onChange={this.handleUpload.bind(this)} className="custom-file-input" id="myInput3" aria-describedby="myInput3" />
                                    <label className="custom-file-label" htmlFor="myInput3">Choose file</label>
                                </div>
                            </div>
                            <div className="meter">
                                <span><span className="meter-progress"></span></span>
                            </div>
                        </div> */}

                        {/* <hr /> */}

                        {/* <br /> */}

                    </div>
                </div>

            </div>
        ) : "Loading..."
    }

}

export default Documents;