import React, { Component } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import {convertDate, orderResult } from '../Utils';
import Loading from './Loading';
import { config } from '../Configurator'

// import CurrencyFormat from 'react-currency-format';

class SourceTable extends Component {

    constructor(props) {
        super(props)
        this.userChange = this.userChange.bind(this);
        this.state = {
            loading: true,
            user: null,
            customers: []
        } 
    }

    userChange(event) {
        let {name: fieldName, value} = event.target;
        this.load(value);
    }

    loadCustomers() {
        this.load(null);
    }

    load(user) {
        if(user===null) user = localStorage.getItem('bo_email');
        this.setState({'loading': true});
        axios.post(config().base_url + 'GetSource', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
                "pRequest": {
                  "Status": "Active",
                  "SortType": "",
                  "SortBy": "",
                  "SearchType": "",
                  "SearchText": "",
                  "RecordStartNo": "1",
                  "RecordEndNo": "10000"
                }
          })
        .then(response => {
            //  alert("loaded");
            this.setState({customers: orderResult(response.data.d.Records, "CustomerReference", "desc"), loading: false});
        })
        .catch(error => {
            this.setState({loading: false});
            console.log(error);
        });
    }

    componentDidMount() {
        this.loadCustomers();
    }

    render() {
        const { customers, loading } = this.state;
        return (
            <div>


                {/* <div className="row d-none">
                    <div className="col-md-4">
                        <select id="user" onChange={this.userChange} value={this.state.user}>
                            <option value="select">Select</option>
                            <option value="Apples">Apples</option>
                            <option value="Mangoes">Mangoes</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        { this.state.user }
                    </div>
                </div> */}


                <div className="row">
                    <div className="col-md-9"><h4 className="font-weight-bold">Sources</h4></div>
                    {/* <div className="col-md-3"><Link className="btn btn-block btn-sm btn-primary" to="/form/32">Add Source</Link></div> */}
                    {/* <div className="col-md-4">
                        { this.state.user }
                    </div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3/2">Quick Quote</Link></div> */}
                    {/* <div className="col-md-2"><Link className="btn btn-block btn-sm btn-primary" to="/form/3">Add Case</Link></div> */}
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> :
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Company</th>
                                    <th>Telephone</th>
                                    <th>Email</th>
                                    <th>Source Status</th>
                                    <th>Records Assigned</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    customers.length ?
                                    customers.map(entry => {
                                        return <tr key={entry.CustomerReference}>
                                            <td>{ entry.SourceCode }</td>
                                            <td>{ entry.SourceName }</td>
                                            <td>{ entry.Telephone }</td>
                                            <td>{ entry.Email }</td>
                                            <td>{ entry.SourceStatus }</td>
                                            <td>{ entry.SourceCaseDetail.TotalCases }</td>
                                            {/* <td>
                                                <Link to={ '/customer/' + entry.CustomerReference } className="btn-more"></Link>
                                            </td> */}
                                        </tr> 
                                    }) : 
                                    null
                                }
                            </tbody>
                        </table>
                    }
                    </div>
                </div>
            </div>
        )
    }

}

export default SourceTable;