import alertify from 'alertifyjs';

export const config = (d) => {

    let domain = window.location.hostname;
    // let subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    
    // let base_url = 'https://brokercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
    // let base_url = 'https://www.mylendology.co.uk/Webservices/BrightOfficeAPI.asmx/';
    let base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
    // let base_url = 'https://trainingcrm.co.uk/Webservices/BrightOfficeAPI.asmx/'; 
    let logo = '/images/clientlogos/logo-brightoffice.png';
    let visible_articles = true; // globaly enabled but it can be disabled for specific users/clients
    let visible_quick_quote_btn = true;
    // let assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
    let cleanUpNames = [''];
    let nav_links = [
    ]
    let assessor_questions = [];

    let forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "link_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "link_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/7", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/19", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities - Draft 3 - 08.04.20.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "link_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "link_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "link_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "link_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "link_case", "resource": "Risk Matrix Portal", "case_status": 'live',  },
            { "name": "Drawdown Request Form", "type": "link_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC",  "case_status": 'live'  },
            // Prospects
            { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/14"  },
            { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
    ];



    // Customer OVERRIDES here:

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Peregrine Config //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.myperegrinefinance.co.uk') {
        base_url = 'https://www.myperegrinefinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/clientlogos/logo-peregrine.png';
        forms = [
            //Updated Packs & Docs 04052021
            // { "name": "Proposal Form", "type": "pdf_case", "resource": "Proposal Form" },
            // { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            // { "name": "Unregulated Hire Purchase Pack", "LetterGroupCode": "Unregulated Hire Purchase Pack", "type": "pdf_case"  },
            // { "name": "Regulated Hire Purchase Pack", "LetterGroupCode": "Regulated Hire Purchase Pack", "type": "pdf_case"  },
            // { "name": "Unregulated Loan Pack", "LetterGroupCode": "Unregulated Loan Agreement Pack", "type": "pdf_case"  },
            // { "name": "Regulated Loan Pack", "LetterGroupCode": "Regulated Fixed Sum Loan Agreement Pack", "type": "pdf_case"  },
            // { "name": "Regulated Hire Pack - Reg Lease", "LetterGroupCode": "Regulated Hire Pack - Reg Lease", "type": "pdf_case"  },
            // { "name": "Unregulated Lease Pack", "LetterGroupCode": "Unregulated Finance Lease Pack", "type": "pdf_case"  },
            // { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate"  },
            // { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate"  },
            // { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate"  },
            // { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020"  },
            // { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121" },
            // { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121" },
            // { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121" },
            // { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "" },
            // { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121" },
            // { "name": "Customer Mandate", "type": "pdf_case", "resource": "Customer Mandate - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121" },
            // { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121" },
            // { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121" },

            //Updated Packs & Docs 02072021
            { "name": "Edit Details", "type": "link_case", "resource": "/form/28"},
            { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            { "name": "Proposal Form - Income Matching", "type": "pdf_case", "resource": "Proposal Form - Income Matching", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Unregulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Loan Agreement", "type": "pdf_case", "resource": "Regulated Fixed Sum Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Loan Agreement", "type": "pdf_case", "resource": "Unregulated Fixed Rate Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Agreement", "type": "pdf_case", "resource": "Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Finance Lease Agreement", "type": "pdf_case", "resource": "Unregulated Finance Lease PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Hire Purchase", "type": "pdf_case", "resource": "Adequate Explanation Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Loan Agreement", "type": "pdf_case", "resource": "Adequate Explanation Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Acceptance Certificate", "type": "pdf_case", "resource": "Acceptance Certificate - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Initial Disclosure", "type": "pdf_case", "resource": "Initial Disclosure and Client Consent to Search Form - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020", "ReturnDocType": "DOC"  },
            { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "Asset Inspection Form - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments - Blank", "type": "pdf_case", "resource": "Schedule of Payments - BLANK", "ReturnDocType": "DOC" },
            //Left but not on list provided
            // { "name": "Customer Mandate", "type": "pdf_case", "resource": "Customer Mandate - PAFV8 0121", "ReturnDocType": "DOC" },

        ];
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // LenderCRM Config //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.lendercrm.co.uk') {
        base_url = 'https://demo.lendercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/clientlogos/logo-brightoffice.png';
        visible_quick_quote_btn = false;
        forms = [
            { "name": "Edit Details", "type": "link_case", "resource": "/form/28"},
            { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            { "name": "Proposal Form - Income Matching", "type": "pdf_case", "resource": "Proposal Form - Income Matching", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Unregulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Loan Agreement", "type": "pdf_case", "resource": "Regulated Fixed Sum Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Loan Agreement", "type": "pdf_case", "resource": "Unregulated Fixed Rate Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Agreement", "type": "pdf_case", "resource": "Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Finance Lease Agreement", "type": "pdf_case", "resource": "Unregulated Finance Lease PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Hire Purchase", "type": "pdf_case", "resource": "Adequate Explanation Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Loan Agreement", "type": "pdf_case", "resource": "Adequate Explanation Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Acceptance Certificate", "type": "pdf_case", "resource": "Acceptance Certificate - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Initial Disclosure", "type": "pdf_case", "resource": "Initial Disclosure and Client Consent to Search Form - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020", "ReturnDocType": "DOC"  },
            { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "Asset Inspection Form - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments - Blank", "type": "pdf_case", "resource": "Schedule of Payments - BLANK", "ReturnDocType": "DOC" },
        ];
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Lendology Config //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.mylendology.co.uk') {
        base_url = 'https://www.mylendology.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/clientlogos/logo-lendology.png';
        visible_articles = false;
        visible_quick_quote_btn = false;
        forms = [
            // Prospect
            { "name": "Create Case", "type": "link_prospect", "resource": "/form/25"  },
            { "name": "Prospect Status", "type": "link_prospect", "resource": "/form/26"  },
            // Case Forms
            { "name": "KYC Application", "type": "link_case", "resource": "/form/32"  },
            { "name": "KYC Application PDF", "type": "pdf_case", "LetterGroupCode": "KYC Form" },
            { "name": "Workflow Status", "type": "link_case", "resource": "/form/35"  },
            { "name": "Case Status", "type": "link_case", "resource": "/form/27"  },
            { "name": "Add Title Details", "type": "link_case", "resource": "/form/36"  },
            // Case Docs
            // { "name": "PCCI Letter Jan 2022", "type": "pdf_case", "resource": "PCCI", "ReturnDocType": "DOC" },
            // { "name": "Unable to Contact letter", "type": "pdf_case", "resource": "UCL1", "ReturnDocType": "DOC" },
            // { "name": "Add. Info Chaser Letter", "type": "pdf_case", "resource": "ADD1", "ReturnDocType": "DOC" },
            // { "name": "Confirmation of Add Info Received Letter", "type": "pdf_case", "resource": "ADD2", "ReturnDocType": "DOC" },
            // { "name": "Pre-Decline Letter", "type": "pdf_case", "resource": "PDC1", "ReturnDocType": "DOC" },
            // { "name": "Decline Letter 1", "type": "pdf_case", "resource": "DECL1", "ReturnDocType": "DOC" },
            // { "name": "Decline Letter 2", "type": "pdf_case", "resource": "DECL2", "ReturnDocType": "DOC" },
            // { "name": "Decline Letter 3", "type": "pdf_case", "resource": "DECL3", "ReturnDocType": "DOC" },
            // { "name": "Loan Paperwork Letter", "type": "pdf_case", "resource": "LPL1", "ReturnDocType": "DOC" },
            // { "name": "Loan Paperwork Letter DEFERRED IRU", "type": "pdf_case", "resource": "LPL2", "ReturnDocType": "DOC" },
            // { "name": "Loan Paperwork Letter IRU No RX1", "type": "pdf_case", "resource": "LPL3", "ReturnDocType": "DOC" },
            // { "name": "Loan Paperwork Letter IRU", "type": "pdf_case", "resource": "LPL4", "ReturnDocType": "DOC" },
            // { "name": "Loan Paperwork Letter No RX1", "type": "pdf_case", "resource": "LPL5", "ReturnDocType": "DOC" },
            // { "name": "Letter to Joint Owner re RX1", "type": "pdf_case", "resource": "LPL6", "ReturnDocType": "DOC" },
            // { "name": "Loan Paperwork Letter BULK BUY FUEL", "type": "pdf_case", "resource": "LPL-BBF", "ReturnDocType": "DOC" },
            // { "name": "Drawdown Letter – CR and LR", "type": "pdf_case", "resource": "DDL1", "ReturnDocType": "DOC" },
            // { "name": "Drawdown Letter – CR and No LR", "type": "pdf_case", "resource": "DDL2", "ReturnDocType": "DOC" },
            // { "name": "Drawdown Letter – Deferred IO", "type": "pdf_case", "resource": "DDL3", "ReturnDocType": "DOC" },
            // { "name": "Drawdown Letter – Deferred IRU", "type": "pdf_case", "resource": "DDL4", "ReturnDocType": "DOC" },
            // { "name": "Drawdown Letter – IO and LR", "type": "pdf_case", "resource": "DDL5", "ReturnDocType": "DOC" },
            // { "name": "Drawdown Letter – IO No LR", "type": "pdf_case", "resource": "DDL6", "ReturnDocType": "DOC" },
            // { "name": "Drawdown Letter - IRU", "type": "pdf_case", "resource": "DDL7", "ReturnDocType": "DOC" },
            // { "name": "Deferred Loan Repayment Commencement Letter", "type": "pdf_case", "resource": "DDL8", "ReturnDocType": "DOC" },
            // { "name": "Letter to Client – Invoice Chase", "type": "pdf_case", "resource": "INV1", "ReturnDocType": "DOC" },
            // { "name": "Direct Debit – Not Commenced", "type": "pdf_case", "resource": "DD1", "ReturnDocType": "DOC" },
            // { "name": "Letter Confirming DD amount increase", "type": "pdf_case", "resource": "DD2", "ReturnDocType": "DOC" },
            // { "name": "Letter re missed payment & DD Cancelled", "type": "pdf_case", "resource": "DD3", "ReturnDocType": "DOC" },
            // { "name": "Letter re set up of new DD & payment for DD due soon", "type": "pdf_case", "resource": "DD4", "ReturnDocType": "DOC" },
            // { "name": "Letter to Client re DD Mandate not Completed", "type": "pdf_case", "resource": "DD5", "ReturnDocType": "DOC" },
            // { "name": "Letter of consent for increased payments", "type": "pdf_case", "resource": "CON4", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re recalling missed payment – no contact", "type": "pdf_case", "resource": "ARR1", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re recalling missed payment – recall date agreed", "type": "pdf_case", "resource": "ARR2", "ReturnDocType": "DOC" },
            // { "name": "2nd Missed Payment Letter", "type": "pdf_case", "resource": "ARR3", "ReturnDocType": "DOC" },
            // { "name": "2nd Missed Payment Letter enc Statutory Arrears Notice and Default Sum Notice", "type": "pdf_case", "resource": "ARR4", "ReturnDocType": "DOC" },
            // { "name": "Outstanding Missed Payment Letter", "type": "pdf_case", "resource": "ARR5", "ReturnDocType": "DOC" },
            // { "name": "3rd Missed Payment Letter – Case to go to BW Legal", "type": "pdf_case", "resource": "ARR6", "ReturnDocType": "DOC" },
            // { "name": "6 Monthly Arrears Letter and Notice", "type": "pdf_case", "resource": "ARR7", "ReturnDocType": "DOC" },
            // { "name": "Letter to Client re Arrears Cleared", "type": "pdf_case", "resource": "ARR8", "ReturnDocType": "DOC" },
            // { "name": "Late Payment Indulgence Letter – Arrears in Full", "type": "pdf_case", "resource": "ARR9", "ReturnDocType": "DOC" },
            // { "name": "Missed Payment Recall Failed Due to Cancelled DD Letter", "type": "pdf_case", "resource": "ARR10", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re cancelled Direct Debit", "type": "pdf_case", "resource": "ARR11", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re extending loan term to clear arrears", "type": "pdf_case", "resource": "ARR12", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re refinancing to clear arrears", "type": "pdf_case", "resource": "ARR13", "ReturnDocType": "DOC" },
            // { "name": "", "type": "pdf_case", "resource": "Balance", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re balance of the loan", "type": "pdf_case", "resource": "BAL1", "ReturnDocType": "DOC" },
            // { "name": "Letter to solicitor re balance of the loan", "type": "pdf_case", "resource": "BAL2", "ReturnDocType": "DOC" },
            // { "name": "Executor Letter confirming balance of the loan and receipt of Probate", "type": "pdf_case", "resource": "37226", "ReturnDocType": "DOC" },
            // { "name": "Executor Letter of condolence and receipt of death certificate", "type": "pdf_case", "resource": "37591", "ReturnDocType": "DOC" },
            // { "name": "Executor Letter of condolence and request for death certificate – no contact", "type": "pdf_case", "resource": "37956", "ReturnDocType": "DOC" },
            // { "name": "Executor letter of condolence and request for death certificate", "type": "pdf_case", "resource": "38322", "ReturnDocType": "DOC" },
            // { "name": "Letter requesting update from Executor (interest freeze ending)", "type": "pdf_case", "resource": "38687", "ReturnDocType": "DOC" },
            // { "name": "Solicitor Letter re Balance of the loan and confirmation of death certificate", "type": "pdf_case", "resource": "39052", "ReturnDocType": "DOC" },
            // { "name": "Letter to client informing of how to pay lump sum reductions", "type": "pdf_case", "resource": "LSR1", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re lump sum reduction received", "type": "pdf_case", "resource": "LSR2", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re SO overpayment allocated to account", "type": "pdf_case", "resource": "LSR3", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re unspent funds", "type": "pdf_case", "resource": "LSR4", "ReturnDocType": "DOC" },
            // { "name": "Letter to client confirming payment break", "type": "pdf_case", "resource": "PB1", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re payment break ending", "type": "pdf_case", "resource": "PB2", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re payment break extending", "type": "pdf_case", "resource": "PB3", "ReturnDocType": "DOC" },
            // { "name": "Consent to Remortgage – SMI Loan", "type": "pdf_case", "resource": "REM1", "ReturnDocType": "DOC" },
            // { "name": "Consent to Remortgage – Covering Letter & Form", "type": "pdf_case", "resource": "REM2", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re remortgage", "type": "pdf_case", "resource": "REM3", "ReturnDocType": "DOC" },
            // { "name": "Letter to solicitor re consent to remortgage", "type": "pdf_case", "resource": "REM4", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re settlement figure", "type": "pdf_case", "resource": "RPL1", "ReturnDocType": "DOC" },
            // { "name": "Letter to solicitor re settlement figure", "type": "pdf_case", "resource": "RPL2", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re repaid via final repayment", "type": "pdf_case", "resource": "RPL3", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re settlement received – other loans outstanding", "type": "pdf_case", "resource": "RPL4", "ReturnDocType": "DOC" },
            // { "name": "Letter to client re settlement received", "type": "pdf_case", "resource": "RPL5", "ReturnDocType": "DOC" },
            // { "name": "Letter to solicitor re settlement received", "type": "pdf_case", "resource": "RPL6", "ReturnDocType": "DOC" },
            // { "name": "Letter to solicitor re acceptance of undertaking – inc. settlement figure", "type": "pdf_case", "resource": "UND1", "ReturnDocType": "DOC" },
            // { "name": "Letter to solicitor re acceptance of undertaking – no settlement figure", "type": "pdf_case", "resource": "UND2", "ReturnDocType": "DOC" },
            // { "name": "Letter to Land Reg enc WCT", "type": "pdf_case", "resource": "WCT1", "ReturnDocType": "DOC" },
            // { "name": "Add Info Chaser Letter – Lendology Lite", "type": "pdf_case", "resource": "ADD1-LL", "ReturnDocType": "DOC" },
            // { "name": "PCCI – Lendology Lite", "type": "pdf_case", "resource": "PCCI-LL", "ReturnDocType": "DOC" },
            // { "name": "Letter to Client re repaid via final payment – Lendology Lite", "type": "pdf_case", "resource": "RPL-LL", "ReturnDocType": "DOC" },
            // { "name": "Loan Paperwork Letter – Lendology Lite", "type": "pdf_case", "resource": "LPL-LL", "ReturnDocType": "DOC" },
            // { "name": "Letter to client confirming how to pay LR fee", "type": "pdf_case", "resource": "LR1", "ReturnDocType": "DOC" },

        // With Cats
        { "name": "PCCI", "type": "pdf_case", "resource": "PCCI - PCCI", "ReturnDocType": "DOC", "group": "PCCI Letters"  },
        { "name": "UCL1", "type": "pdf_case", "resource": "Unable to Contact Letter - UCL1", "ReturnDocType": "DOC", "group": "PCCI Letters"  },
        { "name": "ADD1", "type": "pdf_case", "resource": "Add Info Chaser Letter - ADD1", "ReturnDocType": "DOC", "group": "Additional Info Letters"  },
        { "name": "ADD2", "type": "pdf_case", "resource": "Confirmation of Add Info Received - ADD2", "ReturnDocType": "DOC", "group": "Additional Info Letters"  },
        { "name": "LA1", "type": "pdf_case", "resource": "LA1", "ReturnDocType": "DOC", "group": "Loan Agreement"  },
        { "name": "PDC1", "type": "pdf_case", "resource": "Pre-Decline Letter - PDC1", "ReturnDocType": "DOC", "group": "Decline Letters"  },
        { "name": "DECL1", "type": "pdf_case", "resource": "Decline Letter 1 - DECL1", "ReturnDocType": "DOC", "group": "Decline Letters"  },
        { "name": "DECL2", "type": "pdf_case", "resource": "Decline Letter 2 - DECL2", "ReturnDocType": "DOC", "group": "Decline Letters"  },
        { "name": "DECL3", "type": "pdf_case", "resource": "Decline Letter 3 - DECL3", "ReturnDocType": "DOC", "group": "Decline Letters"  },
        { "name": "LPL1", "type": "pdf_case", "resource": "Loan Paperwork Letter - LPL1", "ReturnDocType": "DOC", "group": "Loan Paperwork Letters"  },
        { "name": "LPL2", "type": "pdf_case", "resource": "Loan Paperwork Letter - Deferred IRU - LPL2", "ReturnDocType": "DOC", "group": "Loan Paperwork Letters"  },
        { "name": "LPL3", "type": "pdf_case", "resource": "Loan Paperwork Letter - IRU No RX1 - LPL3", "ReturnDocType": "DOC", "group": "Loan Paperwork Letters"  },
        { "name": "LPL4", "type": "pdf_case", "resource": "Loan Paperwork Letter - IRU - LPL4", "ReturnDocType": "DOC", "group": "Loan Paperwork Letters"  },
        { "name": "LPL5", "type": "pdf_case", "resource": "Loan Paperwork Letter No RX1 - LPL5", "ReturnDocType": "DOC", "group": "Loan Paperwork Letters"  },
        { "name": "LPL6", "type": "pdf_case", "resource": "Letter to Joint Owner re RX1 - LPL6", "ReturnDocType": "DOC", "group": "Loan Paperwork Letters"  },
        { "name": "LPL-BBF", "type": "pdf_case", "resource": "LPL-BBF", "ReturnDocType": "DOC", "group": "Bulk Buy Fuel Loans"  },
        { "name": "DDL1", "type": "pdf_case", "resource": "Drawdown Letter - CR and LR - DDL1", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "DDL2", "type": "pdf_case", "resource": "Drawdown Letter - CR and No LR - DDL2", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "DDL3", "type": "pdf_case", "resource": "Drawdown Letter - Deferred IO - DDL3", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "DDL4", "type": "pdf_case", "resource": "Drawdown Letter - Deferred IRU - DDL4", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "DDL5", "type": "pdf_case", "resource": "Drawdown Letter - IO and LR - DDL5", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "DDL6", "type": "pdf_case", "resource": "Drawdown Letter - IO No LR - DDL6", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "DDL7", "type": "pdf_case", "resource": "Drawdown Letter - IRU - DDL7", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "DDL8", "type": "pdf_case", "resource": "Deferred Loan Repayment Commencement Letter - DDL8", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        // New Copies
        { "name": "Drawdown letter – Capital Repayment and no LR", "type": "pdf_case", "resource": "Drawdown letter – Capital Repayment and no LR", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "Drawdown Letter - IO and LR", "type": "pdf_case", "resource": "Drawdown Letter - IO and LR ", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "Drawdown Letter for IO no LR", "type": "pdf_case", "resource": "Drawdown Letter for IO no LR", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "Drawdown Letter- IRU", "type": "pdf_case", "resource": "Drawdown Letter- IRU", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        { "name": "Drawdown Letter - CR and No LR", "type": "pdf_case", "resource": "Drawdown Letter - CR and No LR ", "ReturnDocType": "DOC", "group": "Drawdown Letters"  },
        // End of new copies
        { "name": "INV1", "type": "pdf_case", "resource": "INV1", "ReturnDocType": "DOC", "group": "Invoice "  },
        { "name": "DD1", "type": "pdf_case", "resource": "Direct Debit Not Commenced - DD1", "ReturnDocType": "DOC", "group": "Direct Debits"  },
        { "name": "DD2", "type": "pdf_case", "resource": "Letter Confirming DD Amount Increase - DD2", "ReturnDocType": "DOC", "group": "Direct Debits"  },
        { "name": "DD3", "type": "pdf_case", "resource": "Letter re Missed Payment and DD Cancelled - DD3", "ReturnDocType": "DOC", "group": "Direct Debits"  },
        { "name": "DD4", "type": "pdf_case", "resource": "Letter re Set Up of New DD and Payment Due Soon - DD4", "ReturnDocType": "DOC", "group": "Direct Debits"  },
        { "name": "DD5", "type": "pdf_case", "resource": "Letter re DD Mandate Not Completed - DD5", "ReturnDocType": "DOC", "group": "Direct Debits"  },
        { "name": "CON1", "type": "pdf_case", "resource": "Letter of Consent for Increased Payments - CON1", "ReturnDocType": "DOC", "group": "Direct Debits"  },
        { "name": "CON4", "type": "pdf_case", "resource": "CON4", "ReturnDocType": "DOC", "group": "Direct Debits"  },
        { "name": "ARR1", "type": "pdf_case", "resource": "Letter to Client re Recalling Missed Payment - No Contact - ARR1", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR2", "type": "pdf_case", "resource": "Letter to Client re Recalling Missed Payment - Recall Date Agreed - ARR2", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR3", "type": "pdf_case", "resource": "2nd Missed Payment Letter - ARR3", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR4", "type": "pdf_case", "resource": "2nd Missed Payment Letter enc Statutory Arrears Notice and Default Sum Notice - ARR4", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR5", "type": "pdf_case", "resource": "Outstanding Missed Payment Letter - ARR5", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR6", "type": "pdf_case", "resource": "3rd Missed Payment Letter - Case to go to BW Legal - ARR6", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR7", "type": "pdf_case", "resource": "6 Monthly Arrears Letter and Notice - ARR7", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR8", "type": "pdf_case", "resource": "Arrears Cleared Letter - ARR8", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR9", "type": "pdf_case", "resource": "Late Payment Indulgence Letter - Arrears in Full - ARR9", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR10", "type": "pdf_case", "resource": "Missed Payment Recall Failed due to Cancelled DD - ARR10", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR11", "type": "pdf_case", "resource": "Letter re Cancelled DD - ARR11", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR12", "type": "pdf_case", "resource": "Letter re Extending Loan Term to Clear Arrears - ARR12", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR13", "type": "pdf_case", "resource": "Letter re Refinancing to Clear Arrears - ARR13", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR14", "type": "pdf_case", "resource": "Arrears Budget Sheet - ARR14", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR15", "type": "pdf_case", "resource": "Arrears Information Sheet - ARR15", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR16", "type": "pdf_case", "resource": "Arrears Leaflet - ARR16", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "ARR17", "type": "pdf_case", "resource": "Statutory Arrears Notice - ARR17 ", "ReturnDocType": "DOC", "group": "Arrears"  },
        { "name": "BAL1", "type": "pdf_case", "resource": "Letter re Balance of Loan - BAL1", "ReturnDocType": "DOC", "group": "Balance"  },
        { "name": "BAL2", "type": "pdf_case", "resource": "Letter to Solicitor re Balance of Loan - BAL2", "ReturnDocType": "DOC", "group": "Balance"  },
        { "name": "37226", "type": "pdf_case", "resource": "37226", "ReturnDocType": "DOC", "group": "Deceased"  },
        { "name": "37591", "type": "pdf_case", "resource": "37591", "ReturnDocType": "DOC", "group": "Deceased"  },
        { "name": "37956", "type": "pdf_case", "resource": "37956", "ReturnDocType": "DOC", "group": "Deceased"  },
        { "name": "38322", "type": "pdf_case", "resource": "38322", "ReturnDocType": "DOC", "group": "Deceased"  },
        { "name": "38687", "type": "pdf_case", "resource": "38687", "ReturnDocType": "DOC", "group": "Deceased"  },
        { "name": "39052", "type": "pdf_case", "resource": "39052", "ReturnDocType": "DOC", "group": "Deceased"  },
        { "name": "LSR1", "type": "pdf_case", "resource": "Letter to Client re How to Make Lump Sum Reduction - LSR1", "ReturnDocType": "DOC", "group": "Lump Sum Reduction"  },
        { "name": "LSR2", "type": "pdf_case", "resource": "Letter to Client re Lump Sum Reduction Received - LSR2", "ReturnDocType": "DOC", "group": "Lump Sum Reduction"  },
        { "name": "LSR3", "type": "pdf_case", "resource": "Letter to Client re SO Overpayment Allocated to Account - LSR3", "ReturnDocType": "DOC", "group": "Lump Sum Reduction"  },
        { "name": "LSR4", "type": "pdf_case", "resource": "Letter to Client re Unspent Funds - LSR4", "ReturnDocType": "DOC", "group": "Lump Sum Reduction"  },
        { "name": "PB1", "type": "pdf_case", "resource": "Letter to Client Confirming Payment Break - PB1", "ReturnDocType": "DOC", "group": "Payment Break"  },
        { "name": "PB2", "type": "pdf_case", "resource": "Letter to Client re Payment Break Ending - PB2", "ReturnDocType": "DOC", "group": "Payment Break"  },
        { "name": "PB3", "type": "pdf_case", "resource": "Letter to Client re Payment Break Extending - PB3", "ReturnDocType": "DOC", "group": "Payment Break"  },
        { "name": "REM1", "type": "pdf_case", "resource": "Consent to Remortgage (SMI Loan) - REM1", "ReturnDocType": "DOC", "group": "Remortgage"  },
        { "name": "REM2", "type": "pdf_case", "resource": "Consent to Remortgage (Covering Letter and Form) - REM2", "ReturnDocType": "DOC", "group": "Remortgage"  },
        { "name": "REM3", "type": "pdf_case", "resource": "Letter to client re Remortgage - REM3", "ReturnDocType": "DOC", "group": "Remortgage"  },
        { "name": "REM4", "type": "pdf_case", "resource": "Letter to Solicitor re Consent to Remortgage - REM4", "ReturnDocType": "DOC", "group": "Remortgage"  },
        { "name": "RPL1", "type": "pdf_case", "resource": "Letter to Client re Settlement Figure - RPL1", "ReturnDocType": "DOC", "group": "Repaid Loans & Settlement Figures"  },
        { "name": "RPL2", "type": "pdf_case", "resource": "Letter to Solicitor re Settlement Figure - RPL2", "ReturnDocType": "DOC", "group": "Repaid Loans & Settlement Figures"  },
        { "name": "RPL3", "type": "pdf_case", "resource": "Letter to Client re Repaid via Final Repayment - RPL3", "ReturnDocType": "DOC", "group": "Repaid Loans & Settlement Figures"  },
        { "name": "RPL4", "type": "pdf_case", "resource": "Letter to Client re Settlement Received - Other loans Outstanding - RPL4", "ReturnDocType": "DOC", "group": "Repaid Loans & Settlement Figures"  },
        { "name": "RPL5", "type": "pdf_case", "resource": "Letter to Client re Settlement Received - RPL5", "ReturnDocType": "DOC", "group": "Repaid Loans & Settlement Figures"  },
        { "name": "RPL6", "type": "pdf_case", "resource": "Letter to Solicitor re Settlement Received - RPL6", "ReturnDocType": "DOC", "group": "Repaid Loans & Settlement Figures"  },
        { "name": "UND1", "type": "pdf_case", "resource": "Letter to Solicitor re Acceptance of Undertaking inc Settlement Figure - UND1", "ReturnDocType": "DOC", "group": "Undertakings"  },
        { "name": "UND2", "type": "pdf_case", "resource": "Letter to Solicitor re Acceptance of Undertaking no Settlement Figure - UND2", "ReturnDocType": "DOC", "group": "Undertakings"  },
        { "name": "WCT1", "type": "pdf_case", "resource": "Letter to Land Reg enc WCT - WCT1", "ReturnDocType": "DOC", "group": "Unregistered"  },
        { "name": "ADD1-LL", "type": "pdf_case", "resource": "Add Info Chaser Letter (Lite) - ADD1-LL", "ReturnDocType": "DOC", "group": "Lendology LITE"  },
        { "name": "PCCI-LL", "type": "pdf_case", "resource": "PCCI (Lite) - PCCI-LL", "ReturnDocType": "DOC", "group": "Lendology LITE"  },
        { "name": "RPL-LL", "type": "pdf_case", "resource": "Letter to Client re Repaid via Final Repayment (Lite) - RPL-LL", "ReturnDocType": "DOC", "group": "Lendology LITE"  },
        { "name": "LPL-LL", "type": "pdf_case", "resource": "Loan Paperwork Letter (Lite) - LPL-LL", "ReturnDocType": "DOC", "group": "Lendology LITE"  },
        { "name": "LR1", "type": "pdf_case", "resource": "Letter to Client Confirming How to Pay LR Fee - LR1", "ReturnDocType": "DOC", "group": "Land Registry"  },
        ];
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // London Credit Config //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.mylondoncredit.co.uk') {
        base_url = 'https://www.mylondoncredit.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/clientlogos/logo-london-credit.png';
        visible_quick_quote_btn = false;
        forms = [
            // { "name": "Update Application", "type": "link_case", "resource": "/form/34"  },
            { "name": "Agreement in Principle", "type": "pdf_case", "resource": "Agreement in Principle LC" },
        ];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // LendWell Config //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.mylendwell.co.uk') {
        base_url = 'https://www.mylendwell.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/Lendwell_Logo.png';
        forms = [
            { "name": "Heads of Terms Input", "type": "link", "resource": "/form/8"  },
            { "name": "Heads of Terms", "type": "pdf", "resource": "Heads of Terms Template"  },
            { "name": "Proposal Form", "type": "pdf", "resource": "PAF - Proposal Form"  },
            { "name": "Asset and Liability", "type": "docx", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities (1).docx"  }, 
            { "name": "Etridge Letter", "type": "docx", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx"  },
            { "name": "Consent Letter", "type": "docx", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc"  },
            { "name": "Risk Matrix", "type": "pdf", "resource": "Risk Matrix"  },
            { "name": "Credit Report", "type": "pdf", "resource": "Credit Paper - Reward Capital"  },
        ];
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Black and White Config //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.myblackandwhite.co.uk') {
        base_url = 'https://www.myblackandwhite.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/clientlogos/logo-black-white.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        forms = [
            { "name": "Application", "type": "link_case", "resource": "/form/30"  },
        ];
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Testing Config Training//
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // if(domain==='portal.digitusrain.co.uk') {
    //     base_url = 'https://www.trainingcrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
    //     logo = '/images/clientlogos/logo-educ.svg';
    //     visible_articles = false;
    //     visible_quick_quote_btn = false;
    //     // Training
    //     forms = [
    //         { "name": "Book a Course", "type": "link_prospect", "resource": "/form/31"  },
    //         { "name": "Book a Course", "type": "link_case", "resource": "/form/31"  },
    //     ];
    // }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Testing Config Training//
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.trainingcrm.co.uk') {
        base_url = 'https://training.brightoffice.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/clientlogos/logo-BrightOffice.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        // Training
        forms = [
            { "name": "Book a Course", "type": "link_prospect", "resource": "/form/31"  },
            { "name": "Book a Course", "type": "link_case", "resource": "/form/31"  },
        ];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Testing Config Lender//
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    if(domain==='portal.digitusrain.co.uk') {
        // base_url = 'https://demo.lendercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
        base_url = 'https://test.mygranitefinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/clientlogos/logo-educ.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        // Lender
        forms = [
            { "name": "Edit Details", "type": "link_case", "resource": "/form/28"},
            { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            { "name": "Proposal Form - Income Matching", "type": "pdf_case", "resource": "Proposal Form - Income Matching", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Unregulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Loan Agreement", "type": "pdf_case", "resource": "Regulated Fixed Sum Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Loan Agreement", "type": "pdf_case", "resource": "Unregulated Fixed Rate Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Agreement", "type": "pdf_case", "resource": "Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Finance Lease Agreement", "type": "pdf_case", "resource": "Unregulated Finance Lease PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Hire Purchase", "type": "pdf_case", "resource": "Adequate Explanation Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Loan Agreement", "type": "pdf_case", "resource": "Adequate Explanation Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Acceptance Certificate", "type": "pdf_case", "resource": "Acceptance Certificate - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Initial Disclosure", "type": "pdf_case", "resource": "Initial Disclosure and Client Consent to Search Form - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020", "ReturnDocType": "DOC"  },
            { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "Asset Inspection Form - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments - Blank", "type": "pdf_case", "resource": "Schedule of Payments - BLANK", "ReturnDocType": "DOC" },
        ];
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Testing Config Lender-Lendology//
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // if(domain==='portal.digitusrain.co.uk') {
    //     base_url = 'https://www.mylendology.co.uk/Webservices/BrightOfficeAPI.asmx/';
    //     logo = '/images/clientlogos/logo-educ.svg';
    //     visible_articles = false;
    //     visible_quick_quote_btn = false;
    //     // Training
    //     forms = [
    //         { "name": "Create Case", "type": "link_prospect", "resource": "/form/25"  },
    //         { "name": "Prospect Status", "type": "link_prospect", "resource": "/form/26"  },
    //         { "name": "KYC Application", "type": "link_case", "resource": "/form/32"  },
    //         { "name": "1st Appt Letter", "type": "pdf_case", "resource": "1st Appt Letter New", "ReturnDocType": "DOC"  },
    //         { "name": "Case Status", "type": "link_case", "resource": "/form/27"  },
    //     ];
    // }

    if(domain==='crm.evelocity.info') {
        logo = '/images/logo-reward-finance.svg';
    }

    if(domain==='localhost') {
        cleanUpNames = [];
    }

    // if local storage is empty we are probably on the login page
    if(localStorage.getItem('bo_email')===null) {
        let data = {
            'base_url': base_url,
            'logo': logo
        }
        return data;
    }


    return {
        'base_url': base_url,
        'forms': forms,
        'visible_articles': visible_articles,
        'visible_quick_quote_btn': visible_quick_quote_btn,
        'logo': logo,
        'navbarClass': 'white', // dark or white
        'navIconClass': 'dark', // dark or white
        'personName': [localStorage.getItem("bo_user_first_name") ?? '', localStorage.getItem("bo_user_surname") ?? ''].join(' '),
        'custom_css': './css/portal.trainingcrm.co.uk/default.css',
        'personFunction': 'Portal User',
        'personProfileImage': '/images/profile.png',
        'client_id': 1,
        'cleanUpNames': cleanUpNames,
        'nav_links': nav_links,
        'assessor_questions': assessor_questions
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Lendology Users //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     if( localStorage.getItem('bo_email') === 'wessex.resolutions' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Ollie Roby',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'ellie.lister' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Ellie Lister',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'philippa.cooke' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Philippa Cooke',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'emma.lower' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Emma Lower',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'shannon.nutkins' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Shannon Nutkins',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'daisy.birch' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Daisy Birch',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'tony.hawkings' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Tony Hawkings',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'harriet.delrey' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Harriet Delrey',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'max.beale' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Max Beale',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'andy.meldrum' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Andy Meldrum',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'alice.killen' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Alice Killen',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'laura.hill' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Laura Hill',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'anna.osborne' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Anna Osborne',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'kristian.hawkes' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Kristian Hawkes',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'shannon.nutkins' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Shannon Nutkins',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'daisy.birch' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Daisy Birch',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'anna.osborne' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-lendology.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Anna Osborne',
//             'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
    

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Black and White Users //
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     if( localStorage.getItem('bo_email') === 'black.white' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Jamie Ashton',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'vicky.ashby' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Vicky Ashby',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'heather.hancock' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Heather Hancock',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'nick.rideout' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Nick Rideout',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'damien.druce2' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Damien Druce',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'martyn.smith' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Martyn Smith',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'sophie.organ' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Sophie Organ',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'oli.bland2' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Oli Bland',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'nick.lynch' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Nick Lynch',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'lee.carling' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Lee Carling',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'lyn.mcLaren' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Lyn McLaren',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'nicholas.goss' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-black-white.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Nicholas Goss',
//             'custom_css': './css/domain/portal.myblackandwhite.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }

//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // London Credit Users //
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     if( localStorage.getItem('bo_email') === 'Marios.Theophanous' ) {
//         return { 
//         'base_url': base_url, 
//         'forms': forms, 
//         'visible_articles': visible_articles, 
//         'visible_quick_quote_btn': visible_quick_quote_btn, 
//         'logo': '/images/clientlogos/logo-london-credit.png', 
//         'navbarClass': 'white', // dark or white 
//         'navIconClass': 'dark', // dark or white 
//         'personName': 'Marios Theophanous',
//         'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 
//         'personFunction': 'Portal Administrator', 
//         'personProfileImage': '/images/profiles/user.png', 
//         'customerLimitCode': 'C000001', 
//         'client_id': 2, 
//         'cleanUpNames': cleanUpNames, 
//         'nav_links': [], 
//     }
//     }

//     if( localStorage.getItem('bo_email') === 'Katerina.Christoforou' ) {  
//         return { 
//         'base_url': base_url, 
//         'forms': forms, 
//         'visible_articles': visible_articles, 
//         'visible_quick_quote_btn': visible_quick_quote_btn, 
//         'logo': '/images/clientlogos/logo-london-credit.png', 
//         'navbarClass': 'white', // dark or white 
//         'navIconClass': 'dark', // dark or white 
//         'personName': 'Katerina Christoforou', 
//         'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 
//         'personFunction': 'Portal Administrator', 
//         'personProfileImage': '/images/profiles/user.png', 
//         'customerLimitCode': 'C000001', 
//         'client_id': 2, 
//         'cleanUpNames': cleanUpNames, 
//         'nav_links': [], 
//     }
//     }

//     if( localStorage.getItem('bo_email') === 'Sohail.Ahmed' ) {  
//         return { 
//         'base_url': base_url, 
//         'forms': forms, 
//         'visible_articles': visible_articles, 
//         'visible_quick_quote_btn': visible_quick_quote_btn, 
//         'logo': '/images/clientlogos/logo-london-credit.png', 
//         'navbarClass': 'white', // dark or white 
//         'navIconClass': 'dark', // dark or white 
//         'personName': 'Sohail Ahmed', 
//         'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 
//         'personFunction': 'Portal Administrator', 
//         'personProfileImage': '/images/profiles/user.png', 
//         'customerLimitCode': 'C000001', 
//         'client_id': 2, 
//         'cleanUpNames': cleanUpNames, 
//         'nav_links': [], 
//     }
//     }

//     if( localStorage.getItem('bo_email') === 'Costas.Kareklas' ) {  
//         return { 
//             'base_url': base_url, 
//             'forms': forms, 
//             'visible_articles': visible_articles, 
//             'visible_quick_quote_btn': visible_quick_quote_btn, 
//             'logo': '/images/clientlogos/logo-london-credit.png', 
//             'navbarClass': 'white', // dark or white 
//             'navIconClass': 'dark', // dark or white 
//             'personName': 'Costas Kareklas', 'custom_css': 
//             './css/domain/portal.mylondoncredit.co.uk/default.css', 
//             'personFunction': 'Portal Administrator', 
//             'personProfileImage': '/images/profiles/user.png', 
//             'customerLimitCode': 'C000001', 
//             'client_id': 2, 
//             'cleanUpNames': cleanUpNames, 
//             'nav_links': [], 
//         }
//     }

//     if( localStorage.getItem('bo_email') === 'Antrea.Demetriou' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Antrea Demetriou', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Elena.Panayiotou' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Elena Panayiotou', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Aphrodite.Kosma' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Aphrodite Kosma', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Evdokia.Georgiou' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Evdokia Georgiou', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Andy.Georgiou' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Andy Georgiou', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Chris.Stylianides' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Chris Stylianides', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Rafaella.Groutidou' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Rafaella Groutidou', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Joanna.Christou' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Joanna Christou', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Mikaella.Panagi' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Mikaella Panagi', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Ariana.Mavromichalou' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Ariana Mavromichalou', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
//     if( localStorage.getItem('bo_email') === 'Constantinos.Savvides' ) {  return { 'base_url': base_url, 'forms': forms, 'visible_articles': visible_articles, 'visible_quick_quote_btn': visible_quick_quote_btn, 'logo': '/images/clientlogos/logo-london-credit.png', 'navbarClass': 'white',  'navIconClass': 'dark',  'personName': 'Constantinos Savvides', 'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css', 'personFunction': 'Portal Administrator', 'personProfileImage': '/images/profiles/user.png', 'customerLimitCode': 'C000001', 'client_id': 2, 'cleanUpNames': cleanUpNames, 'nav_links': [], }}
    
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     // Demo and Master Users //
//     ////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     if( localStorage.getItem('bo_email') === 'lender.demo' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-brightoffice.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Michael Fairhurst',
//             'custom_css': './css/domain/portal.lendercrm.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [
//                 ['/form/3', 'Form number 3'],
//             ],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'or.brokercrm' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-digitusrain.png',
//             'navbarClass': 'dark', // dark or white
//             'navIconClass': 'white', // dark or white
//             'personName': 'Ollie Roby',
//             'custom_css': './css/portal/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000696',
//             'cleanUpNames': cleanUpNames,
//             'client_id': 2,
//             'nav_links': [
//                 ['/form/3', 'Form number 3'],
//                 ['/form/4', 'Form number 4'],
//                 ['/form/5', 'Form number 5'],
//                 ['/form/6', 'Form number 6'],
//             ],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//    if( localStorage.getItem('bo_email') === 'training.demo' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-brightoffice.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Ollie Roby',
//             'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C001286',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'training.crm' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-educ.svg',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Ollie Roby',
//             'custom_css': './css/domain/portal.digitusrain.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C001286',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'matti.lamb' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-brightoffice.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Matti Lamb',
//             'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C001286',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'lend.well' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/Lendwell_Logo.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Michael Fairhurst',
//             'custom_css': './css/domain/portal.mylendwell.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C004995',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [
//                 ['/form/11', 'Prospect Creation'],
//                 ['/form/7', 'Proposal Form Input'],
//                 ['/form/8', 'Heads of Terms'],
//                 ['/form/9', 'Risk Score'],
//                 ['/form/10', 'Credit Form'],
//             ],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'srdjan.stojkovicapi' ||  localStorage.getItem('bo_email') === 'srdjan.stojkovic' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/logo.png',
//             'navbarClass': 'dark', // dark or white
//             'navIconClass': 'white', // dark or white
//             'personName': 'Ollie Roby',
//             'custom_css': './css/peregrine/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000696',
//             'client_id': 1,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [
//                 ['/form/3', 'Form number 3'],
//                 ['/form/4', 'Form number 4'],
//             ],
//             // 'assessor_questions': assessor_questions
//         }
//     }
//     if( localStorage.getItem('bo_email') === 'broker.api' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-brightoffice.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Ollie Roby',
//             'custom_css': './css/domain/portal.mymintbridging.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C001286',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [
//                 ['/form/11', 'Prospect Creation'],
//                 ['/form/8', 'Heads of Terms'],
//                 ['/form/7', 'Proposal Form'],
//                 ['/form/9', 'Risk Score'],
//                 ['/form/10', 'Credit Form'],
//             ],
//             // 'assessor_questions': assessor_questions
//         }
//     }


//     if( localStorage.getItem('bo_email') === 'london.credit' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-london-credit.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Helen Regan',
//             'custom_css': './css/domain/portal.mylondoncredit.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C000001',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [],
//             // // 'assessor_questions': assessor_questions
//         }
//     }

//     if( localStorage.getItem('bo_email') === 'website.api' ) {
//         return {
//             'base_url': base_url,
//             'forms': forms,
//             'visible_articles': visible_articles,
//             'visible_quick_quote_btn': visible_quick_quote_btn,
//             'logo': '/images/clientlogos/logo-brightoffice.png',
//             'navbarClass': 'white', // dark or white
//             'navIconClass': 'dark', // dark or white
//             'personName': 'Ollie Roby',
//             'custom_css': './css/domain/portal.mymintbridging.co.uk/default.css',
//             'personFunction': 'Portal Administrator',
//             'personProfileImage': '/images/profiles/user.png',
//             'customerLimitCode': 'C001286',
//             'client_id': 2,
//             'cleanUpNames': cleanUpNames,
//             'nav_links': [
//                 ['/form/11', 'Prospect Creation'],
//                 ['/form/8', 'Heads of Terms'],
//                 ['/form/7', 'Proposal Form'],
//                 ['/form/9', 'Risk Score'],
//                 ['/form/10', 'Credit Form'],
//             ],
//             // 'assessor_questions': assessor_questions
//         }
//     }

   
    alertify.confirm('Error', "No config for this user", null, null);
}
