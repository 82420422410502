import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { currency } from '../Utils';
import { config } from '../Configurator'
import Documents from './DocumentsCause'

class CourseView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entry: {},
            customer_reference: null,
            applicant_id: this.props.id,
            name: null,
            forms: config().forms
        }
    }

    componentDidMount() {
        
        // get course details
        axios.post(config().base_url + 'GetSingleTrainingCourseJSON', {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password'),
                "Code": this.props.id
        })
        .then(response => {
            
            // console.log(response);
            
            console.log('applicant_id = ' + response.data.d.CaseApplicant.ApplicantID);
            console.log('customer_ref = ' + response.data.d.CaseApplicant.CustomerReference);

            this.setState({
                entry: response.data.d.CaseApplicant, 
                applicant_id: response.data.d.CaseApplicant.ApplicantID,
                customer_reference: response.data.d.CaseApplicant.CustomerReference,
                name: (response.data.d.CaseApplicant.MainApplicant.Firstname ?? "") + ' ' + (response.data.d.CaseApplicant.MainApplicant.Surname ?? "")});
        })
        .catch(error => {
            console.log(error);
        });


    }
    render() {
        const { entry, customer_reference, name, applicant_id } = this.state;
        return (
            <div>
                <div className="row mt-3">
                    <div className="col-md-8">
                        <div className="row mb-2">
                            <div className="col-9">
                                <h2>Summary</h2>
                            </div>
                            {/* <div className="col-3 text-right">
                                <Link className="float-right btn btn-light btn-sm" to='/cases'>&larr; Back</Link>
                            </div> */}
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">Ref:<br /><strong>{ entry.Code }</strong></div>
                                        <div className="mb-3">Course Description:<br /><strong>{ entry.CourseDescription }</strong></div>
                                        <div className="mb-3">Start Date:<br /><strong>{ entry.CourseDate }</strong></div>
                                        <div className="mb-3">End Date:<br /><strong>{ entry.EndDate }</strong></div>
                                        <div className="mb-3">Duration:<br /><strong>{ entry.Duration }</strong></div>
                                        <div className="mb-3">Available Places:<br /><strong>{ entry.AvailablePlaces }</strong></div>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div className="mb-3">Venue:<br /><strong>{ entry.CourseVenueDescription }</strong></div>
                                        <div className="mb-3">Venue Postcode:<br /><strong>{ entry.Postcode }</strong></div>
                                        <div className="mb-3">Primary Cat:<br /><strong>{ entry.Sector }</strong></div>
                                        <div className="mb-3">Secondary Cat:<br /><strong>{ entry.CourseType }</strong></div>

                                    </div>
                                    <div className="col-md-1"> </div>
                                    <div className="col-md-3">
                                    <div className="mb-3">Status:<br /><strong>{ entry.CourseStatus }</strong></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                            <br />
                                <div className="row">
                                    <div className="col-md-6">Course Overview<br />{ currency(entry.CourseOverview) }</div>
                                    <br /><br />
                                    <div className="col-md-6">Course Content<br />{ currency(entry.CourseContent) }</div>
                                    <br /><br />
                                    <div className="col-md-6">Course Aim<br />{ currency(entry.CourseAim) }</div>
                                    <br /><br />
                                    <div className="col-md-6">Learning Objectives<br />{ currency(entry.LearningObjectives) }</div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h2 className="mb-3">Documents</h2>
                        <Documents customer_reference={this.state.customer_reference} applicant_id={this.state.applicant_id}></Documents>
                    </div>
                </div>
                </div>
        )
    }

}

export default CourseView;