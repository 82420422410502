import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, floatOrZero, dropLocalStorageData } from '../Utils'

const endpoint = "UpdateCase";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeUpdateCaseLendKYC = (att, applicant_id, customer_reference) => {

    if(applicant_id===null) {
        alert("Applicant ID can't be null");
        return false;
    }

    // console.log('passed customer reference ==' + customer_reference);
    // console.log('planned payload===')
    // console.log(updateCasePayload(att, applicant_id, customer_reference))

    // #1 Update case data 
    axios.post(config().base_url + endpoint, updateCasePayload(att, applicant_id, customer_reference)).then(function(response){
        let value = '';
        if(response.data.d.UpdateResult.SuccessCode==0) {

            // #2 if update goes well, get case assessor 

            value = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            if(att.form_id=='13') { // delay for sound to play 
                                setTimeout(function(){ window.location.href = "/case/" + applicant_id;  }, 2800);
                            } else {
                                window.location.href = "/case/" + applicant_id;
                            }
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });

            // window.location.href = "/thanks/" + response.data.d.Value;
        }
    }).catch(function (error){
        console.log('caught error');
        console.log(error);
    });




}

const updateCasePayload = (att, applicant_id, customer_reference) => {
    let ret = {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseApp": {
            "ApplicantID": applicant_id,
            "CustomerReference": customer_reference,
            "AvoidUpdatingBlankData":true,
            "IsUpdateCaseCustomer":true,
            "MainApplicant": {
                "Title": att.App1Title,
                "Firstname": att.App1FirstName,
                "Surname": att.App1Surname,
                "DateOfBirth": convertDateForApi(att.App1DOB),
                "Telephone": att.App1HomePhone,
                "WorkTelephone": '',
                "Mobile": att.App1MobilePhone,
                "EmailAddress": att.App1EmailAddress,
                "CurrentAddress": {
                    "HouseName": att.App1CurrentAddressHouseName,
                    "HouseNumber": att.App1CurrentAddressHouseNumber,
                    "Address1": att.App1CurrentAddress1,
                    "Address2": att.App1CurrentAddress2,
                    "Address3": att.App1CurrentAddress3,
                    "Address4": att.App1CurrentAddress4,
                    "PostCode": att.App1CurrentPostcode,
                    "TimeInYears": att.App1YearsAt
                },
                "PreviousAddresses": [
                    {
                    "id": null,
                    "HouseName": att.App1PreviousAddressHouseName,
                    "HouseNumber": att.App1PreviousAddressHouseNumber,
                    "Address1": att.App1PreviousAddress1,
                    "Address2": att.App1PreviousAddress2,
                    "Address3": att.App1PreviousAddress3,
                    "Address4": att.App1PreviousAddress4,
                    "Postcode": att.App1PreviousPostcode,
                    "TimeInYears": att.App1PrevAddressYears
                },
                {
                  "id": null,
                  "HouseName": att.App1PreviousAddressHouseName2,
                  "HouseNumber": att.App1PreviousAddressHouseNumber2,
                  "Address1": att.App1PreviousAddress12,
                  "Address2": att.App1PreviousAddress22,
                  "Address3": att.App1PreviousAddress32,
                  "Address4": att.App1PreviousAddress42,
                  "Postcode": att.App1PreviousPostcode2,
                  "TimeInYears": att.App1PrevAddressYears2
              }
            ],
            "EmploymentDetails": {
              "OccupationType": "",
              "EmploymentStatus": att.App1EmploymentStatus,
              "EmployerName": att.App1EmployersName,
              "Address1": att.App1EmployerAddress1,
              "Address2": att.App1EmployerAddress2,
              "Address3": att.App1EmployerAddress3,
              "Address4": att.App1EmployerAddress4,
              "Postcode": att.App1EmployerAddressPostCode,
              "EmployerTelephone": "",
              "TimeInYears": att.App1TimeInEmployment,
              "TimeInMonths": "",
              "FirstIncome": "",
              "SecondIncome": "",
              "OtherIncomeDescription": "",
              "PartTimeHoursWorked": ""
            },
            },
            "AdditionalApplicants": [
                    {
                        "Title": att.App2Title,
                        "Firstname": att.App2FirstName,
                        "Surname": att.App2Surname,
                        "DateOfBirth": convertDateForApi(att.App2DOB),
                        "MaritalStatus": null,
                        "Telephone": att.App2HomePhone,
                        "WorkTelephone": null,
                        "Mobile": att.App2MobilePhone,
                        "EmailAddress": att.App2EmailAddress,
                        "OtherName": null,
                        "PreviousName": null,
                        "CurrentAddress": {
                            "HouseName": att.App2CurrentAddressHouseName,
                            "HouseNumber": att.App2CurrentAddressHouseNumber,
                            "Address1": att.App2CurrentAddress1,
                            "Address2": att.App2CurrentAddress2,
                            "Address3": att.App2CurrentAddress3,
                            "Address4": att.App2CurrentAddress4,
                            "PostCode": att.App2CurrentPostcode,
                            "TimeInYears": att.App2YearsAt
                        },
                        "PreviousAddresses": [
                            {
                            "HouseName": att.App2PreviousAddressHouseName,
                            "HouseNumber": att.App2PreviousAddressHouseNumber,
                            "Address1": att.App2PreviousAddress1,
                            "Address2": att.App2PreviousAddress2,
                            "Address3": att.App2PreviousAddress3,
                            "Address4": att.App2PreviousAddress4,
                            "PostCode": att.App2PreviousPostcode,
                            "TimeInYears": att.App2PrevAddressYears
                        },
                        {
                          "HouseName": att.App2PreviousAddressHouseName2,
                          "HouseNumber": att.App2PreviousAddressHouseNumber2,
                          "Address1": att.App2PreviousAddress12,
                          "Address2": att.App2PreviousAddress22,
                          "Address3": att.App2PreviousAddress32,
                          "Address4": att.App2PreviousAddress42,
                          "PostCode": att.App2PreviousPostcode2,
                          "TimeInYears": att.App2PrevAddressYears2
                      }
                    ],
                    "EmploymentDetails": {
                      "OccupationType": "",
                      "EmploymentStatus": att.App2EmploymentStatus,
                      "EmployerName": att.App2EmployersName,
                      "Address1": att.App2EmployerAddress1,
                      "Address2": att.App2EmployerAddress2,
                      "Address3": att.App2EmployerAddress3,
                      "Address4": att.App2EmployerAddress4,
                      "Postcode": att.App2EmployerAddressPostCode,
                      "EmployerTelephone": "",
                      "TimeInYears": att.App2TimeInEmployment,
                      "TimeInMonths": "",
                      "FirstIncome": "",
                      "SecondIncome": "",
                      "OtherIncomeDescription": "",
                      "PartTimeHoursWorked": ""
                    },
                        "App2ID": att.App2ID,
                      }
                ],
            "MatterType": '',
            "Provider": '',
            "ProviderDescription": '',
            "ProviderRef": '',
            "ProviderCaseRef": '',
            "OldCaseRef": '',
            "CreditType": '',
            "NetClaim": att.NetClaim,
            "Commission": att.Commission,
            "ClaimInterest": att.ClaimInterest,
            "CustomerInitialFee": att.CustomerInitialFee,
            "CustomerCompletionFeePerc": att.CustomerCompletionFeePerc,
            "CustomerCompletionFee": att.CustomerCompletionFee,
            "CaseStatus": att.CaseStatus,
            "StatusDate": convertDateForApi(""),
            "Source": att.Source,
            "SourceRef": '',
            "SourceAffiliate": '',
            "DateCreated": convertDateForApi(""),
            //"UserCreated": null,
            "WorkflowStep": '',
            //"DaysOld": null,
            "CaseOwner": '',
            "CustomerOwner": '',
            "BrokerCode": '',
            "BrokerName": '',
            "BrokerReference": '',
            "MultiContacts": multiplyFields(att, {
              "CustomFields": [{
                  "CustomFieldNo": 1,
                  "CustomFieldValue": 'att.mc1_CustomFieldValue1'
              },
              {
                  "CustomFieldNo": 2,
                  "CustomFieldValue": 'att.mc1_CustomFieldValue2'
              },
              {
                  "CustomFieldNo": 3,
                  "CustomFieldValue": 'att.mc1_CustomFieldValue3'
              },
              {
                  "CustomFieldNo": 6,
                  "CustomFieldValue": 'att.mc1_CustomFieldValue6'
              }
              ],
              "Firstname": 'att.mc1_Firstname',
              "Surname": 'att.mc1_Surname',
              "Guarantor": 'att.Guarantors',
              "IncludeInPIP": 'att.mc1_IncludeInPIP',
              "Address1": 'att.mc1_address1',
              "Address2": 'att.mc1_address2',
              "Address3": 'att.mc1_address3',
              "Address4": 'att.mc1_address4',
              "ForceDelete": 'att.mc1_ForceDelete',
              "PostCode": 'att.mc1_postcode',
              "DirectPhone": 'att.mc1_telephone',
              "Mobile": 'att.mc1_mobile',
              "Email": 'att.mc1_email',
              "Position": 'att.mc1_position',
              "DateOfBirth": 'att.mc1_DateOfBirth'
          }),
            "DynamicFieldDetail": [{
                "FieldNo": 1,
                "FieldValue": ""
            },
            {
                "FieldNo": 2,
                "FieldValue": att.purpose
            },
            {
                "FieldNo": 3,
                "FieldValue": att.dynamicData3
            },
            {
                "FieldNo": 4,
                "FieldValue": att.dynamicData4
            },
            {
                "FieldNo": 5,
                "FieldValue": att.dynamicData5
            },
            {
                "FieldNo": 6,
                "FieldValue": att.dynamicData6
            },
            {
                "FieldNo": 8,
                "FieldValue": ""
            },
            {
                "FieldNo": 11,
                "FieldValue": ""
            },
            {
                "FieldNo": 12,
                "FieldValue": att.dynamicData12
            }
            ],
            "MonthlyIncome": [
                  {
                    "_id": att.App1SalaryID,
                    "Code": att.App1SalaryID,
                    "TypeCode": "Salary",
                    "BasisCode": att.App1SalaryFreq,
                    "Amount": floatOrZero(att.App1Salary),
                    "Description": "App 1 Salary",
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1Salary),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1PensionID,
                    "Code": att.App1PensionID,
                    "TypeCode": "Pension Credit",
                    "BasisCode": att.App1PensionCreditFreq,
                    "Amount": floatOrZero(att.App1PensionCredit),
                    "Description": "App 1 Pension Credit",
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1PensionCredit),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1StatePensionID,
                    "Code": att.App1StatePensionID,
                    "TypeCode": "Pension (State)",
                    "BasisCode": att.App1StatePensionFreq,
                    "Amount": floatOrZero(att.App1StatePension),
                    "Description": "App 1 Pension State",
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1StatePension),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1PrivatePensionID,
                    "Code": att.App1PrivatePensionID,
                    "TypeCode": "Pension (Private)",
                    "BasisCode": att.App1PrivatePensionFreq,
                    "Amount": floatOrZero(att.App1PrivatePension),
                    "Description": "App 1 Pension Private",
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1PrivatePension),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1RentalIncomeID,
                    "Code": att.App1RentalIncomeID,
                    "TypeCode": "Rental Income",
                    "BasisCode": att.App1RentalIncomeFreq,
                    "Amount": floatOrZero(att.App1RentalIncome),
                    "Description": "App 1 Rental Income",
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1RentalIncome),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1PartnerContributionID,
                    "Code": att.App1PartnerContributionID,
                    "TypeCode": "Income Support",
                    "BasisCode": att.App1PartnerContributionFreq,
                    "Amount": floatOrZero(att.App1PartnerContribution),
                    "Description": "App 1 Partner Contribution",
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1PartnerContribution),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1StateBenefitID,
                    "Code": att.App1StateBenefitID,
                    "TypeCode": "State Benefits",
                    "BasisCode": att.App1StateBenefitFreq,
                    "Amount": floatOrZero(att.App1StateBenefit),
                    "Description": att.App1StateBenefitType,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1StateBenefit),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App2SalaryID,
                    "Code": att.App2SalaryID,
                    "TypeCode": "Salary",
                    "BasisCode": att.App2SalaryFreq,
                    "Amount": floatOrZero(att.App2Salary),
                    "Description": "App 2 Salary",
                    "Client": "2nd Client",
                    "MonthlyAmount": floatOrZero(att.App2Salary),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App2PensionCreditID,
                    "Code": att.App2PensionCreditID,
                    "TypeCode": "Pension Credit",
                    "BasisCode": att.App2PensionCreditFreq,
                    "Amount": floatOrZero(att.App2PensionCredit),
                    "Description": "App 2 Pension Credit",
                    "Client": "2nd Client",
                    "MonthlyAmount": floatOrZero(att.App2PensionCredit),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App2StatePensionID,
                    "Code": att.App2StatePensionID,
                    "TypeCode": "Pension (State)",
                    "BasisCode": att.App2StatePensionFreq,
                    "Amount": floatOrZero(att.App2StatePension),
                    "Description": "App 1 Pension State",
                    "Client": "2nd Client",
                    "MonthlyAmount": floatOrZero(att.App2StatePension),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App2PrivatePensionID,
                    "Code": att.App2PrivatePensionID,
                    "TypeCode": "Pension (Private)",
                    "BasisCode": att.App2PrivatePensionFreq,
                    "Amount": floatOrZero(att.App2PrivatePension),
                    "Description": "App 1 Pension Private",
                    "Client": "2nd Client",
                    "MonthlyAmount": floatOrZero(att.App2PrivatePension),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App2RentalIncomeID,
                    "Code": att.App2RentalIncomeID,
                    "TypeCode": "Rental Income",
                    "BasisCode": att.App2RentalIncomeFreq,
                    "Amount": floatOrZero(att.App2RentalIncome),
                    "Description": "App 2 Rental Income",
                    "Client": "2nd Client",
                    "MonthlyAmount": floatOrZero(att.App2RentalIncome),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App2PartnerContributionID,
                    "Code": att.App2PartnerContributionID,
                    "TypeCode": "Income Support",
                    "BasisCode": att.App2PartnerContributionFreq,
                    "Amount": floatOrZero(att.App2PartnerContribution),
                    "Description": "App 2 Partner Contribution",
                    "Client": "2nd Client",
                    "MonthlyAmount": floatOrZero(att.App2PartnerContribution),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App2StateBenefitID,
                    "Code": att.App2StateBenefitID,
                    "TypeCode": "State Benefits",
                    "BasisCode": att.App2StateBenefitFreq,
                    "Amount": floatOrZero(att.App2StateBenefit),
                    "Description": att.App2StateBenefitType,
                    "Client": "2nd Client",
                    "MonthlyAmount": floatOrZero(att.App2StateBenefit),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  }
                ],
              "MonthlyExpence": [
                  {
                    "_id": att.App1MortgageID,
                    "Code": att.App1MortgageID,
                    "TypeCode": "Mortgage Repayment",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1Mortgage),
                    "Description": att.App1MortgageNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1Mortgage),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1SecuredLoanID,
                    "Code": att.App1SecuredLoanID,
                    "TypeCode": "Loan Repayments",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1SecuredLoan),
                    "Description": att.App1SecuredLoanNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1SecuredLoan),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1CouncilTaxID,
                    "Code": att.App1CouncilTaxID,
                    "TypeCode": "Council Tax",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1CouncilTax),
                    "Description": att.App1CouncilTaxNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1CouncilTax),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1CouncilTaxID,
                    "Code": att.App1BuildingsInsuranceID,
                    "TypeCode": "Insurance (house)",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1BuildingsInsurance),
                    "Description": att.App1BuildingsInsuranceNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1BuildingsInsurance),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1UtilitiesID,
                    "Code": att.App1UtilitiesID,
                    "TypeCode": "Utilities",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1Utilities),
                    "Description": att.App1UtilitiesNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1Utilities),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1CommunicationsID,
                    "Code": att.App1CommunicationsID,
                    "TypeCode": "Communications",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1Communications),
                    "Description": att.App1CommunicationsNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1Communications),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1TravelID,
                    "Code": att.App1TravelID,
                    "TypeCode": "Travel",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1Travel),
                    "Description": att.App1TravelNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1Travel),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1InsurancesID,
                    "Code": att.App1InsurancesID,
                    "TypeCode": "Insurance (Car/Life)",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1Insurances),
                    "Description": att.App1InsurancesNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1Insurances),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1FoodHouseholdID,
                    "Code": att.App1FoodHouseholdID,
                    "TypeCode": "Food/Housekeeping",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1FoodHousehold),
                    "Description": att.App1FoodHouseholdNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1FoodHousehold),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  },
                  {
                    "_id": att.App1OtherOutgoingID,
                    "Code": att.App1OtherOutgoingID,
                    "TypeCode": "Misc",
                    "BasisCode": null,
                    "Amount": floatOrZero(att.App1OtherOutgoing),
                    "Description": att.App1OtherOutgoingNotes,
                    "Client": "1st Client",
                    "MonthlyAmount": floatOrZero(att.App1OtherOutgoing),
                    "CurrentValue": null,
                    "OutstandingBalance": null
                  }
                ]
        }
    }


    ret.CaseApp.SecurityAddress = [];
    // handle securities 
    for(let i=0; i<100; i++) {
        if(typeof att['SecuirtyCode__' + i] !=='undefined') {
            console.log(att['SecuirtyCode__' + i]);

            ret.CaseApp.SecurityAddress.push(
                {
                    "Code": att['SecuirtyCode__' + i],
                    "HouseName": '',
                    "HouseNumber": "",
                    "Address1": "",
                    "Address2": '',
                    "Address3": '',
                    "Address4": "",
                    "Postcode": "",
                    "CurrentValue": att['CurrentValue__' + i],
                    "SecuirtyType": att['secuirtyType__' + i],
                    "SecuirtyDetails": att['secuirtyDetails__' + i],
                    "SecuirtyCharge": null,
                    "SecuirtyChargeValue": att['SecuirtyChargeValue__' + i],
                    "SecuirtyChargeDate": convertDateForApi(null),
                    "SecuirtyEquityValue": att['SecuirtyEquityValue__' + i],
                    "SecuirtyLendingValue": att['SecuirtyLendingValue__' + i],
                    "SecuirtyPurchaseValue": 0,
                    "SecuirtyPurchaseDate": convertDateForApi(''),
                    "SecuirtyMortgageValue": 0,
                    "SecuirtyMarketValue": Number(att['security_multiplier__' + i]),
                    "SecurityTitleNumber": '',
                    "SecurityCustomField": [
                        {
                            "CustomFieldNo": "1",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "2",
                            "CustomFieldValue": ""
                        },
                        {
                            "CustomFieldNo": "5",
                            "CustomFieldValue": ""
                        }
                    ]
                }
             );
        }
    }
    return ret;
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {
    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeUpdateCaseLendKYC
};
