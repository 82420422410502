import React, { Component } from 'react';
import { getDataAnalyticsbyModule, currency, chartAggregatedData } from '../Utils';
import { SelectPicker, DateRangePicker } from 'rsuite';
import moment from 'moment';
import { Chart } from "react-google-charts";

// import 'rsuite/dist/rsuite.min.css'; 

class KPI2 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            years: ['2022', '2021', '2020', '2019', '2018'].map(
                item => ({ label: item, value: item })
              ),
            months: [
                {label: 'All', value: -1},
                {label: 'Jan', value: 0},
                {label: 'Feb', value: 1},
                {label: 'Mar', value: 2},
                {label: 'Apr', value: 3},
                {label: 'May', value: 4},
                {label: 'Jun', value: 5},
                {label: 'Jul', value: 6},
                {label: 'Aug', value: 7},
                {label: 'Sept', value: 8},
                {label: 'Oct', value: 9},
                {label: 'Nov', value: 10},
                {label: 'Dec', value: 11},
            ],
            chart1: [ 
                [ "Month", "Value" ], 
                [ "Jan", 0 ] 
            ],
            chart2: [
                ["Element", "Density", { role: "style" }],
                ["Copper", 8.94, "#b87333"]
              ],
            month: 0,
            year: 2022,
            title: "",
            kpi: [
            ]
        }
    }

    setDates(e) {
        this.loadKPI(moment(e[0]), moment(e[1]))
    }

    setDatesForCharts(e) {
        this.loadKPICharts('chart1', moment(e[0]), moment(e[1]))
        this.loadKPICharts('chart2', moment(e[0]), moment(e[1]))
    }

    setMonth(m) {
        console.log('m=' + m)
        let y = this.state.year;
        this.setState({month: m})
        if(m===null || m===-1) {
            let d1 = moment().year(y).startOf('year')
            let d2 = moment().year(y).endOf('year')
            this.loadKPI(d1, d2)
        } else {
            let d1 = moment().year(y).month(m).startOf('month')
            let d2 = moment().year(y).month(m).endOf('month')
            this.loadKPI(d1, d2)
        }
    }

    setYear(y) {
        console.log('y=' + y)
        if(y===null) {
            y = moment().format('YYYY')
        }
        let m = this.state.month;
        this.setState({year: y})
        if(m===null || m===-1) {
            let d1 = moment().year(y).startOf('year')
            let d2 = moment().year(y).endOf('year')
            this.loadKPI(d1, d2)
        } else {
            let d1 = moment().year(y).month(m).startOf('year')
            let d2 = moment().year(y).month(m).endOf('year')
            this.loadKPI(d1, d2)
        }
    }

    componentDidMount() {
        let currentYear = moment();
        let year = currentYear.format('YYYY')
        this.setState({
            year: year,
        })

        this.loadKPI()
        this.loadKPICharts('chart1')
        this.loadKPICharts('chart2')
    }

    loadKPI(dateFrom=null, dateTo=null) {

        getDataAnalyticsbyModule(dateFrom, dateTo).then(resp => {

            let kpi = [];
            let title = ""; 

            if(resp && resp.data && resp.data.length>0) {
                let response = resp.data;
                kpi.push({'title': 'Live Customers', 'value': response[0].CaseDataAnalyticsRecord.NumberOfLiveCustomers});
                kpi.push({'title': 'Live Cases', 'value': response[0].CaseDataAnalyticsRecord.NumberOfLiveCases});
                kpi.push({'title': 'Principle Balance Forecast', 'value': response[0].CaseDataAnalyticsRecord.TotalPrincipleBalanceForecast});
                kpi.push({'title': 'Balance Forecast', 'value': response[0].CaseDataAnalyticsRecord.TotalBalanceForecast});
                kpi.push({'title': 'Total Arrears Balance', 'value': response[0].CaseDataAnalyticsRecord.TotalArrearsBalance });
                kpi.push({'title': 'Cases Created', 'value': response[0].CaseDataAnalyticsRecord.NumberOfCasesCreated});
                kpi.push({'title': 'Cancelled Cases', 'value': response[0].CaseDataAnalyticsRecord.NumberOfCancelledCases});
                kpi.push({'title': 'Completed Cases', 'value': response[0].CaseDataAnalyticsRecord.NumberOfCompletedCases});


                kpi.push({'title': 'Col Expected No of Customers', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.CollectionsExpected_NumberofCustomers});
                kpi.push({'title': 'Col Expected Value', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.CollectionsExpected_TotalValue});
                kpi.push({'title': 'Col Attempt No of Customers', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.CollectionsAttempted_NumberofCustomers});
                kpi.push({'title': 'Col Attempted Value', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.CollectionsAttempted_TotalValue});
                kpi.push({'title': 'Col Failed No of Customers', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.CollectionsFailed_NumberofCustomers});
                kpi.push({'title': 'Col Failed Value', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.CollectionsFailed_TotalValue});
                kpi.push({'title': 'Payments Received Not DD', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.NumberofPaymentsReceivedNotDirectDebit});
                kpi.push({'title': 'Payment Received Not DD', 'value': response[1].DirectDebitCaseDataAnalyticsRecord.TotalAmountofPaymentsReceivedNotDirectDebit});

                kpi.forEach(function(item) {
                    // if it looks like number, format as number
                    if(typeof(item.value) == 'number' && (item.value % 1 != 0)) {
                        item.value = currency(item.value)
                    }
                    if(typeof(item.value) == 'number' && (item.value % 1 == 0) && item.value>999) {
                        item.value = currency(item.value, 0)
                    }
                })

            }            

            if(resp && resp.title) {
                title = resp.title;
            }
            

            this.setState({kpi: kpi, title: title }, () => {
            });

        });
    }

    async loadKPICharts(chart, dateFrom=null, dateTo=null) {

        // conversion to moment
        dateFrom = (dateFrom===null) ? moment().startOf('month') : moment(dateFrom)
        dateTo = (dateTo===null) ? moment().endOf('month') : moment(dateTo)
        let module = (chart==='chart1') ? 'SalesEnquiries' : 'SalesOrders'
        let diffInDays = dateFrom.diff(dateTo, 'days')
        let resolution = 'days'
        if(diffInDays>31) {
            resolution = 'weeks'
        } 
        if(diffInDays>62) {
            resolution = 'months'
        }

        console.log('diff days===' + diffInDays)
        console.log('resolution===' + resolution)
        
        let response = [[ "Month", "Value" ]];
        let max = 50;

        while(dateFrom<dateTo && max>0) {

            let d1 = moment(dateFrom.format('YYYY-MM-DD'))
            let d2 = moment(dateFrom.format('YYYY-MM-DD')).add(resolution, 1)
            let data = await chartAggregatedData(module, d1, d2)
            response = [...response, ...data]
            dateFrom.add(resolution, 1)
            max--
        }

        if(chart==='chart1') {
            this.setState({ chart1: response })
        }

        if(chart==='chart2') {
            this.setState({ chart2: response })
        }

    }

    async loadKPIChartsCall(dateFrom=null, dateTo=null) {
        return []
    }



    render() {
        const { kpi, years, months, chart1, chart2, title } = this.state;
        return (
            <div>

                <div className='row'>
                    <div className='col-2'>
                        <SelectPicker placeholder="Year" block data={years} onChange={e=>this.setYear(e)}  />
                    </div>
                    <div className='col-2'>
                        <SelectPicker placeholder="Month" block data={months} onChange={e=>this.setMonth(e)} />                        
                    </div>
                    <div className='col-2'>
                        <DateRangePicker placeholder="Custom range" block onChange={e=>this.setDates(e)} />
                    </div>
                    <div className='col-4'>
                        <p className='pt-2'>{ title }</p>
                    </div>
                </div>

                <div className="row mt-2 mb-2">
                    {kpi.map((entity, index) => (  
                        <div className="col-3 mt-3 mb-3">
                            <div key={index} className="card bg-primary text-white">
                                <div className='card-body'>
                                    <p className="card-title">{ entity.title }</p>
                                    <h5 className="card-text text-right">
                                        <hr />
                                        { entity.value }</h5>
                                </div>
                            </div>
                        </div>
                        ))
                        }                    
                </div>

                <div className='row'>
                    <div className='col-2'>
                    </div>
                    <div className='col-2'>
                    </div>
                    <div className='col-2'>
                        <DateRangePicker block onChange={e=>this.setDatesForCharts(e)} />
                    </div>
                    <div className='col-4'>
                        <p className='pt-2'></p>
                    </div>
                </div>

                <div className="row mt-2 mb-2">
                    <div className='col-6'>
                        <Chart
                            chartType="ColumnChart"
                            data={chart1}
                            width="100%"
                            height="400px"
                        />
                    </div>
                    <div className='col-6'>
                        <Chart
                            chartType="ColumnChart"
                            data={chart2}
                            width="100%"
                            height="400px"
                        />
                    </div>
                </div>

            </div>
        )
    }

}

export default KPI2;