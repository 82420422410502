import React from 'react';
import CourseView from '../components/CourseView';
import Navbar from '../components/Navbar';

export default function Course(props) {
    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        {/* <h2>Cases</h2> */}
                        <CourseView />
                    </div>
                </div>
            </div>
        </div>
    );
}