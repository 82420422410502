import axios from 'axios';
import { config } from '../Configurator'
import { convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "CreateNewProspectV2";

export const executeCreateNewProspectV2Lend = (att) => {
    
    let payload = {
        "userInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "customer": {
            "Title1": att.Title,
            "Firstname": att.Firstname,
            "Surname": att.Surname,
            "Title2": att.Title2,
            "Firstname2": att.Firstname2,
            "Surname2": att.Surname2,
            "CompanyName": att.CompanyName,
            "Telephone": att.Telephone,
            "Mobile": att.Mobile,
            "Email": att.Email,
            "DateOfBirth": convertDateForApi(""),
            "CustomerStatus": "Prospect",
            "B2BCustomer": att.B2BIn,
            "Source": att.Source,
            "Notes": att.Notes,
            "SupervisorConfirmer": att.SuperConfirmer,
            "AltSalutation": att.AltSalutation,
            "InvoiceTermName":"",
            "CustomerAddress": {
                "HouseName": att.houseName,
                "HouseNumber": att.houseNumber,
                "Address1": att.address1,
                "Address2": att.address2,
                "Address3": att.address3,
                "Address4": att.address4,
                "PostCode": att.postcode
            },
          "AdditionalApplicantDetails": {
            "AdditionalApplicantDetails": [
              {
                "ApplicantNumber": "2",
                "Title": att.Title2,
                "Firstname": att.Firstname2,
                "Surname": att.Surname2,
                "MaritalStatus": null,
                "Telephone": att.App2Telephone,
                "WorkTelephone": null,
                "Mobile": att.App2Mobile,
                "EmailAddress": att.App2Email,
                "OtherName": null,
                "PreviousName": null,
                "CurrentAddress": {
                    "HouseName": att.App2houseName,
                    "HouseNumber": att.App2houseNumber,
                    "Address1": att.App2address1,
                    "Address2": att.App2address2,
                    "Address3": att.App2address3,
                    "Address4": att.App2address4,
                    "PostCode": att.App2postcode,
                    "TimeInYears": att.App2YearsAt
              },
            }
            ]
          },
            "CustomFields": [
                {
                  "index": "1",
                  "CustomFieldValue": att.CustomFieldValue1
                },
                {
                    "index": "2",
                    "CustomFieldValue": att.CustomFieldValue2
                  },
                  {
                    "index": "3",
                    "CustomFieldValue": att.CustomFieldValue3
                  },
                  {
                    "index": "4",
                    "CustomFieldValue": att.CustomFieldValue4
                  },
                  {
                    "index": "5",
                    "CustomFieldValue": att.CustomFieldValue5
                  },
                  {
                    "index": "6",
                    "CustomFieldValue": att.CustomFieldValue6
                  },
                  {
                    "index": "7",
                    "CustomFieldValue": att.CustomFieldValue7
                  },
                  {
                    "index": "8",
                    "CustomFieldValue": att.CustomFieldValue8
                  },
                  {
                    "index": "9",
                    "CustomFieldValue": att.CustomFieldValue9
                  },
                  {
                    "index": "10",
                    "CustomFieldValue": att.CustomFieldValue10
                  },
                  {
                    "index": "11",
                    "CustomFieldValue": att.CustomFieldValue11
                  },
                {
                  "index": "12",
                  "CustomFieldValue": att.CustomFieldValue12
                }
              ]
        },
        "ClientPassword": ""
    }

    axios.post(config().base_url + endpoint, payload).then(function(response){
        if(response.data.d.UpdateResult.SuccessCode==0) {
            dropLocalStorageData();
            window.location.href = "/prospect/" + response.data.d.CustomerReference;
        } else {
          alert(response.data.d.UpdateResult.Message);
        }
    }).catch(function (error){
        console.log('caught error');
        console.log(error);
    });

}

export default {
    executeCreateNewProspectV2Lend
};
