import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { config } from '../Configurator'

class TasksRecent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cases: []
        }
    }

    componentDidMount() {
        axios.post(config().base_url + 'GetCaseByFilterJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "filter": "CaseID|=|C001286||",
            "withDataFolder": "false",
            "withFiles": "false"
        })
        .then(response => {
            // console.log(response.data.d.Records);
            this.setState({cases: response.data.d.Records.slice(0,3)});
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        const { cases } = this.state;
        return (
            <div>
                <h2>Tasks</h2>
                <div className="row">
                    <div className="col-12 mt-3">
                        
                        <div className="card mb-3">
                            {
                                cases.length ?
                                cases.map(entry => {
                                    return <div key={ entry.ApplicantID }>
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-3">Task. Ref<br /><strong>L00002354</strong></div>
                                            <div className="col-3">Task for:<br /><strong>John Jones</strong></div>
                                            <div className="col-3">Date Raised:<br /><strong>19/10/2020</strong></div>
                                            <div className="col-3"><h5 className="text-center"><div className="badge badge-success">Closed</div></h5></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            </div>
                                            <div className="col-md-2">
                                                <Link to={ '#' } className="btn-more"></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                }) : 
                                <div className='card mb-3'>
                                <div className='card-body row'>
                                    <div className='col-12'>
                                        No current records
                                    </div>
                                </div>
                            </div>
                            }
                        </div> 

                    </div>
                </div>
            </div>
        )
    }

}

export default TasksRecent;