import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, parseNum, dropLocalStorageData } from '../Utils'

const endpoint = "CreateCaseJSON";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeCreateCaseJSONLondonCredit = (att, applicant_id) => {


    let payload_1 = createCasePayload(att, applicant_id);
    // console.log(payload_1);
    // return false;

    axios.post(config().base_url + endpoint, payload_1).then(function (response) {
        if(response.data.d.EnquiryResult.SuccessCode==0) {

            applicant_id = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            window.location.href = "/case/" + applicant_id;
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });
            
        }
    }).catch(function (error) {
        console.log('caught error');
        console.log(error);
    });

}


const createCasePayload = (att, applicant_id, customer_reference) => {

    return {

        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseType": "LoansMort",
        "CaseParams": {
            "Params": {
                "customerCode": customer_reference,
                "matterType": "Bridging-Finance",
                "provider": "London Credit",
                "providerProduct": "Bridging Loan",
                "LoanType": "Bridging",
                "productCode": att.productCode,
                "providerRef": att.providerRef,
                "totalLoan": convertToDouble( parseNum(att.GrossLoan)),
                "term": att.LoanTerm,
                "termPeriod": "M",
                "InterestMonthly": att.InterestRate,
                "UnderWriter": att.UnderWriter,
                "Processor": att.Processor,
                "UserID": att.UserID,
                "AdditionalUser1": att.AdditionalUser1,
                "AdditionalUser2": att.AdditionalUser2,
                "Team": att.Team,
                "source": att.IntroducerCompanyName,
                "SourceEmail": att.IntroducerEmail,
                "IsSourceNeedToCreate": "true",
                "SourceAddress": {
                    "houseName": att.SourceAddress_houseName,
                    "houseNumber": att.SourceAddress_houseNumber,
                    "address1": att.IntroducerCompanyAddress1,
                    "address2": att.IntroducerCompanyAddress2,
                    "address3": att.IntroducerCompanyAddress3,
                    "address4": att.IntroducerCompanyAddress4,
                    "postcode": att.IntroducerCompanyPostcode
                },
                "sourceref": att.sourceref,
                "moneyfor": att.moneyfor,
                "sourceAffiliate": att.IntroducerName,
                "sourceInitialFeePerc": att.sourceInitialFeePerc,
                "affiliateInitialFeePerc": att.affiliateInitialFeePerc,
                "affiliateInitialFee": convertToDouble( parseNum(att.affiliateInitialFee)),
                "ExitFeePerc": att.ExitFeePerc,
                "ExitFee": convertToDouble( parseNum(att.ExitFee)),
                "InitialFeePerc": att.InitialFeePerc,
                "InitialFee": convertToDouble( parseNum(att.ArrangementFee)),
                "caseStatus": "Application",
                "MaritalStatus": att.App1MaritalStatus,
                "OccupancyStatus": "",
                "CurrentAddressYears": att.App1YearsAt,
                "CurrentAddressMonths": att.App1MonthsAt,
                "App1EmploymentDetails": {
                    "OccupationType": att.App1Occupation,
                    "EmploymentStatus": "",
                    "EmployerName": att.App1EmployersName,
                    "Address1": att.App1EmployersAddress1,
                    "Address2": att.App1EmployersAddress2,
                    "Address3": att.App1EmployersAddress3,
                    "Address4": att.App1EmployersAddress4,
                    "Postcode": att.App1EmployersPostcode,
                    "EmployerTelephone": "",
                    "TimeInYears": "",
                    "TimeInMonths": "",
                    "FirstIncome": att.App1AnnualSalary,
                    "SecondIncome": att.App1OtherRegIncome,
                    "OtherIncomeDescription": "",
                    "PartTimeHoursWorked": ""
                  },
                "app1PreviousAddress": [{
                    "houseName": "",
                    "houseNumber": null,
                    "address1": att.App1PrevAddress1,
                    "address2": att.App1PrevAddress2,
                    "address3": att.App1PrevAddress3,
                    "address4": att.App1PrevAddress4,
                    "postcode": att.App1PrevPostcode,
                    "timeInYears": null,
                    "timeInMonths": null
                }],
                "DynamicFieldDetail": [{
                    "FieldNo": 1,
                    "FieldValue": att.dynamicData1
                },
                {
                    "FieldNo": 2,
                    "FieldValue": att.dynamicData2
                },
                {
                    "FieldNo": 3,
                    "FieldValue": att.dynamicData3
                },
                {
                    "FieldNo": 4,
                    "FieldValue": att.dynamicData4
                },
                {
                    "FieldNo": 5,
                    "FieldValue": att.dynamicData5
                },
                {
                    "FieldNo": 6,
                    "FieldValue": att.LoanPurpose
                },
                {
                    "FieldNo": 7,
                    "FieldValue": att.dynamicData6
                },
                {
                    "FieldNo": 8,
                    "FieldValue": att.dynamicData8
                },
                {
                    "FieldNo": 9,
                    "FieldValue": att.dynamicData9
                },
                {
                    "FieldNo": 10,
                    "FieldValue": att.dynamicData9
                },
                {
                    "FieldNo": 11,
                    "FieldValue": att.dynamicData11
                },
                {
                    "FieldNo": 12,
                    "FieldValue": att.dynamicData12
                }
                ],
                "SecurityAddress": ({
                    "houseName": '',
                    "houseNumber": '',
                    "address1": att.SecurityAdd1,
                    "address2": att.SecurityAdd2,
                    "address3": att.SecurityAdd3,
                    "address4": att.SecurityAdd4,
                    "postcode": att.SecurityPostcode,
                    "CurrentValue": att.LoanAmount,
                    "SecuirtyType": att.PropertyType,
                    "SecuirtyDetails": 'att.asset1_securityDetails',
                    "SecuirtyCharge": att.ExistingCharge,
                    "SecurityTitleNumber": 'att.asset1_TitleNumber'
                }),
                "customerDetails": {
                    "customerCode": customer_reference,
                    "b2B": att.b2B,
                    "IsUpdateCustomerDetail" : true,
                    "AlternativeSalutation": att.CorpDate,
                    "title": att.App1Title,
                    "firstName": att.App1Forename,
                    "middleName": "",
                    "surname": att.App1Surname,
                    "houseName": att.houseName,
                    "houseNumber": att.houseNumber,
                    "address1": att.App1Address1,
                    "address2": att.App1Address2,
                    "address3": att.App1Address3,
                    "address4": att.App1Address4,
                    "postcode": att.App1Postcode,
                    "Website" : att.Jurisdiction,
                    "CustomerGroup": att.CustomerGroup,
                    "CompanyName": att.Trust,
                    "source": att.source,
                    "SourceEmail": att.SourceEmail,
                    "IsSourceNeedToCreate": "false",
                    "SourceAddress": {
                        "houseName": att.SourceAddress_houseName,
                        "houseNumber": att.SourceAddress_houseNumber,
                        "address1": att.SourceAddress_address1,
                        "address2": att.SourceAddress_address2,
                        "address3": att.SourceAddress_address3,
                        "address4": att.SourceAddress_address4,
                        "postcode": att.SourceAddress_postcode
                    },
                    "CompanyAddress": {
                        "address1": att.RegisteredAddress1,
                        "address2": att.RegisteredAddress2,
                        "address3": att.RegisteredAddress3,
                        "address4": att.RegisteredAddress4,
                        "address5": "",
                        "postcode": att.RegisteredPostcode
                    },
                    "MultiContacts": multiplyFields(att, {
                        "CustomFields": [{
                            "CustomFieldNo": 1,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                        },
                        {
                            "CustomFieldNo": 2,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                        },
                        {
                            "CustomFieldNo": 3,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                        },
                        {
                            "CustomFieldNo": 4,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue4'
                        },
                        {
                            "CustomFieldNo": 5,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue5'
                        },
                        {
                            "CustomFieldNo": 6,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                        },
                        {
                            "CustomFieldNo": 7,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue7'
                        },
                        {
                            "CustomFieldNo": 8,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue8'
                        },
                        {
                            "CustomFieldNo": 9,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue9'
                        },
                        {
                            "CustomFieldNo": 10,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue10'
                        },
                        ],
                        "Firstname": 'att.mc1_Firstname',
                        "Surname": 'att.mc1_Surname',
                        // "Guarantor": 'att.mc1_Guarantor',
                        "Address1": 'att.mc1_address1',
                        "Address2": 'att.mc1_address2',
                        "Address3": 'att.mc1_address3',
                        "Address4": 'att.mc1_address4',
                        "PostCode": 'att.mc1_postcode',
                        "DirectPhone": 'att.mc1_telephone',
                        "Mobile": 'att.mc1_mobile',
                        "Email": 'att.mc1_email',
                        "Position": 'att.mc1_position',
                        "DateOfBirth": 'att.mc1_DateOfBirth'
                    },

                    ),
                    "CustomFields": [
                        {
                          "index": "1",
                          "CustomFieldValue": att.App1Dependants
                        },
                        {
                            "index": "2",
                            "CustomFieldValue": att.App1DependantAges
                          },
                          {
                            "index": "3",
                            "CustomFieldValue": att.App1Residency
                          },
                          {
                            "index": "4",
                            "CustomFieldValue": att.App1Permanent
                          },
                          {
                            "index": "5",
                            "CustomFieldValue": att.DeclarationConvicted
                          },
                          {
                            "index": "6",
                            "CustomFieldValue": att.DeclarationInsurerDecline
                          },
                          {
                            "index": "7",
                            "CustomFieldValue": att.DeclarationIfYes
                          },
                          {
                            "index": "8",
                            "CustomFieldValue": att.CustomFieldValue8
                          },
                          {
                            "index": "16",
                            "CustomFieldValue": att.App2Dependants
                          },
                          {
                            "index": "17",
                            "CustomFieldValue": att.App2DependantAges
                          },
                          {
                            "index": "18",
                            "CustomFieldValue": att.App2Residency
                          },
                        {
                          "index": "19",
                          "CustomFieldValue": att.App2Permanent
                        },
                        {
                            "index": "20",
                            "CustomFieldValue": att.Declaration2Convicted
                          },
                          {
                            "index": "21",
                            "CustomFieldValue": att.Declaration2InsurerDecline
                          },
                          {
                            "index": "22",
                            "CustomFieldValue": att.Declaration2IfYes
                          }
                      ],
                    "telephone": att.App1Telephone,
                    "mobile": att.App1Mobile,
                    "company": att.Company,
                    "email": att.App1Email,
                    "loanAmount": att.loanAmount,
                    "applicants": null,
                    "othername": att.CompanyNo,
                    "ninumber": att.NatureofBusiness,
                    "ContactMethod": [
                        ""
                    ],
                    "notes": att.notes,
                    "ClientStatus": att.ClientStatus,
                    "IsDeduplicationReq": true,
                    "BankDetail": [{
                        "BankName": att.BankName,
                        "AccountName": att.BankAccountName,
                        "AccountNumber": att.BankAccountNo,
                        "SortCode": att.BankSortCode
                    }]
                },
                "otherApplicantDetails": [
                    {
                        "applicantNumber": 2,
                        "title": att.App2Title,
                        "firstName": att.App2Forename,
                        "middleName": null,
                        "surname": att.App2Surname,
                        "houseName": null,
                        "houseNumber": null,
                        "address1": att.App2Address1,
                        "address2": att.App2Address2,
                        "address3": att.App2Address3,
                        "address4": att.App2Address4,
                        "postcode": att.App2Postcode,
                        "telephone": att.App2Telephone,
                        "mobile": att.App2Mobile,
                        "sex": null,
                        "email": att.App2Email,
                        "maritalStatus": null,
                        "currentAddressYears": att.App2YearsAt,
                        "currentAddressMonths": att.App2MonthsAt,
                        "currentAddressEstimatedValue": null,
                        "currentAddressPurchasePrice": null,
                        "previousAddress": [
                            {
                                "houseName": null,
                                "houseNumber": null,
                                "address1": att.App2PrevAddress1,
                                "address2": att.App2PrevAddress2,
                                "address3": att.App2PrevAddress3,
                                "address4": att.App2PrevAddress4,
                                "postcode": att.App2PrevPostcode,
                                "timeInYears": null,
                                "timeInMonths": null
                            }
                        ],
                        "employmentDetails": {
                            "occupationType": att.App2Occupation,
                            "employmentStatus": null,
                            "employerName": att.App2EmployersName,
                            "address1": att.App2EmployersAddress1,
                            "address2": att.App2EmployersAddress2,
                            "address3": att.App2EmployersAddress3,
                            "address4": att.App2EmployersAddress4,
                            "postcode": att.App2EmployersPostcode,
                            "timeInYears": null,
                            "timeInMonths": null,
                            "firstIncome": att.App2AnnualSalary,
                            "secondIncome": att.App2OtherRegIncome,
                            "otherIncomeDescription": null,
                            "partTimeHoursWorked": null
                        },
                        "previousEmploymentDetails": [
                            {
                                "occupationType": null,
                                "employmentStatus": null,
                                "employerName": "",
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null,
                                "firstIncome": null,
                                "secondIncome": null,
                                "otherIncomeDescription": null,
                                "partTimeHoursWorked": null
                            }
                        ]
                    }
                ]
            }
        }

    }
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {

    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeCreateCaseJSONLondonCredit
};
