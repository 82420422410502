import React from 'react';
import DelegateView from '../components/DelegateView';
import DataFolderArea from '../components/DataFolderArea';
import Navbar from '../components/Navbar';

export default function Delegate(props) {

    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <DelegateView id={props.match.params.id}  />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <br /><br />
                        <h2>Files</h2>
                        <DataFolderArea caseId={props.match.params.id} ></DataFolderArea> 

                    </div>
                </div>

                {/* 
                <div className="row">
                    <div className="col-md-8">
                        <br />
                        <h2>Latest Tasks</h2>
                        <TasksRecent />
                    </div>
                </div>
                */}

            </div>


        </div>
    );
}