import React from 'react';
import DataFolderArea from '../components/DataFolderArea';
import Navbar from '../components/Navbar';

export default function TestPage(props) {
    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Test Page</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <DataFolderArea caseId="CA051405"></DataFolderArea>
                    </div>
                </div>


            </div>


        </div>
    );
}